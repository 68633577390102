import styled from 'styled-components'

import { AppLogo, StartIcon } from '../../config/icons'
import { useQuiz } from '../../context/QuizContext'
import { useShuffleQuestions } from '../../hooks'
import {
    CenterCardContainer,
    HighlightedText,
    LogoContainer,
    PageCenter,
} from '../../styles/Global'
import { ScreenTypes } from '../../types'
import { convertSeconds } from '../../utils/helpers'

import Button from '../ui/Button'

const AppTitle = styled.h2`
  font-weight: 700;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.themeColor};
`

const DetailTextContainer = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 40px;
  text-align: center;
  max-width: 500px;
`

const DetailText = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
  line-height: 1.3;
`

const QuizDetailsScreen = () => {
    const { setCurrentScreen, quizDetails } = useQuiz()

    const { selectedQuizTopic, totalQuestions, totalScore, totalTime } = quizDetails

    const goToQuestionScreen = () => {
        setCurrentScreen(ScreenTypes.QuestionScreen)
    }

    // to shuffle or randomize quiz questions
    useShuffleQuestions()

    return (
        <PageCenter light justifyCenter>
            <CenterCardContainer>
                <LogoContainer>
                    <AppLogo />
                </LogoContainer>
                <AppTitle>EXAM BOOSTER</AppTitle>
                <DetailTextContainer>
                    <DetailText>
                        Selected Quiz Topic: <HighlightedText>{selectedQuizTopic}</HighlightedText>
                    </DetailText>
                    <DetailText>
                        Total questions to attempt:{' '}
                        <HighlightedText>{totalQuestions}</HighlightedText>
                    </DetailText>
                    <DetailText>
                        Score in total: <HighlightedText>{totalScore}</HighlightedText>
                    </DetailText>
                    <DetailText>
                        Total time: <HighlightedText>{convertSeconds(totalTime)}</HighlightedText>
                    </DetailText>
                    <DetailText>
                        To save time, you can skip questions. Skipped questions will show up at the
                        end of the quiz.
                    </DetailText>
                </DetailTextContainer>
                <Button
                    text="Start"
                    icon={<StartIcon />}
                    iconPosition="left"
                    onClick={goToQuestionScreen}
                    bold
                />
            </CenterCardContainer>
        </PageCenter>
    )
}

export default QuizDetailsScreen
