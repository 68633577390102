import { useEffect } from 'react'

import { useQuiz } from '../context/QuizContext'
import { ScreenTypes } from '../types'
import { shuffleArray } from '../utils/helpers'

export const useShuffleQuestions = () => {
  const { setQuestions, currentScreen, questions, quizDetails } = useQuiz()

  useEffect(() => {
    if (currentScreen === ScreenTypes.QuizDetailsScreen) {
      // Limit the questions to the correct number after shuffling
      // setQuestions(shuffleArray(questions))
      setQuestions(shuffleArray(questions).slice(0, quizDetails.totalQuestions))
    }
  }, [currentScreen])
}

export default useShuffleQuestions
