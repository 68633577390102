// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const washington: Topic = {
  topic: 'Washington',
  level: 'Beginner',
  totalQuestions: 120,
  totalScore: 120,
  totalTime: 5400,
  questions: [
    {
      id: 'bc5718f0-6da7-40bd-84d6-cb464e42f51a',
      question: 'An immediate annuity has been purchased with a single premium. When does the annuitant typically begin receiving benefit payments?',
      choices: ['1 month', '12 months', '6 months', '24 months'],
      type: 'MCQs',
      correctAnswers: ['1 month'],
      score: 1,
      additionalInfo: 'When an immediate annuity is funded with a single premium, the annuitant may begin receiving payments after 1 month.',
    },
    {
      id: '3abe1ccc-84e1-4506-ad56-8690426159de',
      question: 'The typical long-term care insurance policy is designed to provide a minimum of __ year(s) of coverage?',
      choices: ['1', '3', '5', '7'],
      type: 'MCQs',
      correctAnswers: ['1'],
      score: 1,
      additionalInfo: 'Long-term care insurance in any insurance policy is designed to provide coverage for a minimum benefit period of 1 year.',
    },
    {
      id: '82f9bc9f-7bc9-4e92-a032-8eeb636acc9c',
      question: 'A life insurance policy becomes incontestable after it has been in force for',
      choices: ['2 years', '30 days', '6 months', '3 years'],
      type: 'MCQs',
      correctAnswers: ['2 years'],
      score: 1,
      additionalInfo: 'A life insurance policy becomes incontestable after it has been in force for 2 years.',
    },
    {
      id: '71f4dfdd-8476-4660-bf9b-252be479c287',
      question: 'A health or disability policy is incontestable after it has been in force for a period of',
      choices: ['2 years', '3 years', '4 years', '5 years'],
      type: 'MCQs',
      correctAnswers: ['2 years'],
      score: 1,
      additionalInfo: 'A health or disability policy is incontestable after it has been in force for a period of 2 years.',
    },
    {
      id: '0e4dba82-1da2-48f4-bb92-cbe4042f7b74',
      question: 'No legal action can be initiated after _____ from the initial time written proof of loss has been provided.',
      choices: ['2 years', '3 years', '4 years', '5 years'],
      type: 'MCQs',
      correctAnswers: ['3 years'],
      score: 1,
      additionalInfo: 'No legal action can be initiated after 3 years from the initial time written proof of loss has been provided.',
    },
    {
      id: '3464c6b9-ad6f-4fa3-82e6-aa1e756f5c47',
      question: 'The Commission of Insurance serves a term of __ years.',
      choices: ['2', '3', '4', '5'],
      type: 'MCQs',
      correctAnswers: ['4'],
      score: 1,
      additionalInfo: 'The Commissioner of Insurance serves a term of 4 years.',
    },
    {
      id: '9f7be6ac-212b-47be-8150-e19656b85565',
      question: 'The Commissioner is REQUIRED to examine admitted insurers AT LEAST every',
      choices: ['2 years', '4 years', '5 years', '6 years'],
      type: 'MCQs',
      correctAnswers: ['5 years'],
      score: 1,
      additionalInfo: 'The Commissioner is required to examine admitted insurers at least every 5 years.',
    },
    {
      id: '7dad2623-55b3-4795-a812-25922935c230',
      question: 'The Commissioner MUST examine domestic insurers AT LEAST once every',
      choices: ['year', '3 years', '5 years', '7 years'],
      type: 'MCQs',
      correctAnswers: ['5 years'],
      score: 1,
      additionalInfo: 'The Commissioner must examine domestic insurers at least once every 5 years.',
    },
    {
      id: '6982fcf1-d354-4d93-8088-c6f6bc01ed5d',
      question: 'The legal action provision in a health contract is limited to no more than __ years.',
      choices: ['2', '3', '4', '5'],
      type: 'MCQs',
      correctAnswers: ['5'],
      score: 1,
      additionalInfo: 'The time limit for a legal action provision in a contract is limited to no more than 5 years.',
    },
    {
      id: '01f0ba7f-51db-4ac0-9a96-a729a08ece37',
      question: 'How long may an insurer exclude coverage for a preexisting condition on a Medicare Supplement policy?',
      choices: ['3 months', '4 months', '5 months', '6 months'],
      type: 'MCQs',
      correctAnswers: ['6 months'],
      score: 1,
      additionalInfo: 'An insurer may exclude coverage for a preexisting condition on a Medicare Supplement policy for 6 months.',
    },
    {
      id: '2faab3b3-6ef0-42c9-ab68-27cd44432dbc',
      question: 'The grace period for an accident and health (disability) policy is no less than ____ for weekly premium polices.',
      choices: ['3 days', '5 days', '7 days', '9 days'],
      type: 'MCQs',
      correctAnswers: ['7 days'],
      score: 1,
      additionalInfo: 'The grace period for a health policy is no less than 7 days for weekly premium policies.',
    },
    {
      id: '4a0813aa-284b-4a68-bf35-2ec5156321fa',
      question: 'A 10% excise tax is normally applied to an early withdrawal from an IRA. According to HIPAA, this tax will not be applied if the withdrawal is used for medical expenses that exceed ____ of the individual\'s adjusted gross income.',
      choices: ['5%', '6.5%', '7.5%', '8%'],
      type: 'MCQs',
      correctAnswers: ['7.5%'],
      score: 1,
      additionalInfo: 'HIPAA provides that the 10% excise tax for early withdrawal from IRAs will not apply to the extent a withdrawal is used for medical expenses that exceed 7.5% of the individual\'s adjusted gross income.',
    },
    {
      id: '6c4d15ac-5193-4720-9ad8-7a7920daef14',
      question: 'A person cannot sell long-term care insurance unless that person is licensed as an insurance producer and has successfully completed a one-time education course of',
      choices: ['4 hours', '6 hours', '8 hours', '10 hours'],
      type: 'MCQs',
      correctAnswers: ['8 hours'],
      score: 1,
      additionalInfo: 'A person cannot sell long-term care insurance unless that person is licensed as an insurance producer and has successfully completed a one-time education course of 8 hours.',
    },
    {
      id: '2ffeb905-12d2-4510-9537-99e3c43f69c8',
      question: 'A life insurance policy loan shall bear interest at a specified rate, NOT in excess of',
      choices: ['6%', '8%', '10%', '12%'],
      type: 'MCQs',
      correctAnswers: ['8%'],
      score: 1,
      additionalInfo: 'The maximum fixed interest rate for policy loans charged by insurers is 8% in Washington.',
    },
    {
      id: 'a0dfe786-8ffb-4037-b0cc-b6b32834ec88',
      question: 'If an accident and health (disability) policy is reinstated after it had lapsed for nonpayment, there is a waiting period of ____ before a claim covering sickness will be covered.',
      choices: ['5 days', '10 days', '15 days', '20 days'],
      type: 'MCQs',
      correctAnswers: ['10 days'],
      score: 1,
      additionalInfo: 'If a health policy is reinstated after it had lapsed for nonpayment, there is a waiting period of 10 days before a claim covering sickness will be covered.',
    },
    {
      id: '6693030e-84ad-4923-94d6-5e5ea4d0eb23',
      question: 'The free look period provided in a life insurance policy is usually',
      choices: ['10 days', '31 days', '45 days', '60 days'],
      type: 'MCQs',
      correctAnswers: ['10 days'],
      score: 1,
      additionalInfo: 'Life insurance policies must provide a minimum free look period of 10 days upon policy delivery. This allows the policy owner time to decide whether or not to keep it.  If the policy owner decides not to keep the policy within the 10 days allowed, a full refund will be given.',
    },
    {
      id: '1c0c4764-edad-4cbe-9a50-fcf5fd06fbb4',
      question: 'Karen is a producer who has obtained personal information about a client without having a legitimate reason to do so. Under the McCarran-Ferguson Act, what is the minimum penalty for this?',
      choices: ['$0', '$5,000', '$10,000', '$15,000'],
      type: 'MCQs',
      correctAnswers: ['$10,000'],
      score: 1,
      additionalInfo: 'A fine of $10,000 or up to one year in jail is the penalty for any person who obtains information about a client without having a legitimate reason to receive it.',
    },
    {
      id: 'd3f8d2a9-b68e-4c4b-bc36-a12b1cd16389',
      question: 'Notice of a claim must be given within how many days after a covered loss?',
      choices: ['20 days', '30 days', '40 days', '60 days'],
      type: 'MCQs',
      correctAnswers: ['20 days'],
      score: 1,
      additionalInfo: 'Notice of a claim must be given within 20 days after a covered loss.',
    },
    {
      id: 'bd9f4b11-020d-4962-834a-e08438ec791e',
      question: 'Which of the following is NOT an eligible dependent on an Accident and Health policy?',
      choices: ['19 year old full-time student', '25 year old married child', '27 year old married child', 'Unmarried disabled child'],
      type: 'MCQs',
      correctAnswers: ['27 year old married child'],
      score: 1,
      additionalInfo: 'Eligible dependents are covered up to age 26 on Accident and Health policies under the Affordable Care Act (ACA).',
    },
    {
      id: 'c8d24c0a-65fe-4c46-829a-3e36d249e69b',
      question: 'The required grace period for life insurance policies in Washington is',
      choices: ['10 days', '20 days', '30 days', '45 days'],
      type: 'MCQs',
      correctAnswers: ['30 days'],
      score: 1,
      additionalInfo: 'The required grace period for life insurance policies in Washington in 30 days.',
    },
    {
      id: 'e6baf385-78f1-4bc0-a6c0-2887c9c7dab2',
      question: 'Each policy of life insurance shall contain a provision that a grace period shall be allowed within which the payment of any premium after the first may be made. The standard grace period shall be',
      choices: ['15 days', '30 days', '45 days', '60 days'],
      type: 'MCQs',
      correctAnswers: ['30 days'],
      score: 1,
      additionalInfo: 'Each policy of life insurance shall contain a provision that a grace period shall be allowed within which the payment of any premium after the first may be made. The standard grace period shall be 30 days.',
    },
    {
      id: '3bd5cf8a-6592-4c65-9cab-62ddcf0a6a68',
      question: 'If an insurer terminates a producer\'s appointment, it must notify thee Commissioner within how many days of the termination?',
      choices: ['15', '25', '30', '60'],
      type: 'MCQs',
      correctAnswers: ['30'],
      score: 1,
      additionalInfo: 'If an insurer terminates a producer\'s appointment, it must notify the Commissioner within 30 days of termination.',
    },
    {
      id: 'f99dd81c-de29-4f96-8ee6-7cc5faa9dbd2',
      question: 'In a Long-Term Care policy, the Right-to-Examine period is',
      choices: ['10 days', '30 days', '45 days', '60 days'],
      type: 'MCQs',
      correctAnswers: ['30 days'],
      score: 1,
      additionalInfo: 'In Washington, the free-look period for Long-Term Care policies is 30 days.',
    },
    {
      id: 'c26b2fe1-992f-4f9c-a725-403cba473579',
      question: 'Greg had recently been terminated from his job that covered him for group life insurance. How long does he have to convert his coverage to an individual policy?',
      choices: ['30 days', '31 days', '45 days', '60 days'],
      type: 'MCQs',
      correctAnswers: ['31 days'],
      score: 1,
      additionalInfo: 'A person covered by a group policy also has the right to convert such coverage to an individual policy within the conversion period (31 days) without proving insurability. If this right is exercised, the employee is responsible for the payment of premium.',
    },
    {
      id: '5019946e-b0de-4d40-871c-e4b980970fd85019946e-b0de-4d40-871c-e4b980970fd8',
      question: 'Written notice for a health claim must be given to the insurer ___ days after the occurrence of the loss.',
      choices: ['10', '20', '31', '60'],
      type: 'MCQs',
      correctAnswers: ['20'],
      score: 1,
      additionalInfo: 'Written notice for a health claim must be given to the insurer 20 days after the occurrence of the loss.',
    },
    {
      id: '50c32b6a-5375-4c55-84fe-c0484335a47d',
      question: 'Kate has a Major Medical Plan with 75/25 coinsurance and a deductible of $25. How much will she have to pay if she, not having met any of her deductible, visits the doctor and receives a bill for $125?',
      choices: ['$25.00', '$50.00', '$75.00', '$100.00'],
      type: 'MCQs',
      correctAnswers: ['$50.00'],
      score: 1,
      additionalInfo: 'In this situation, the insured will have to pay $25 deductible plus $25 coinsurance = $50.',
    },
    {
      id: 'ff79bf38-e682-4e5e-a00e-9b3bc9faa7ce',
      question: 'A Roth IRA owner must be at least what age in order to make tax-free withdrawals?',
      choices: ['59 1/2 and owned account for minimum of 5 years', '59 1/2 and owned account for minimum of 10 years', '70 1/2 and owned account for minimum of 5 years', '70 1/2 and owned account for minimum of 10 years'],
      type: 'MCQs',
      correctAnswers: ['59 1/2 and owned account for minimum of 5 years'],
      score: 1,
      additionalInfo: 'To be able to make tax-free withdrawals from a Roth IRA, an account owner should be AT LEAST age 59 1/2 and have held the account for at least 5 years.',
    },
    {
      id: '9bb52453-13b5-47a7-b26d-f8a27744eeb2',
      question: 'No legal action can be initiated within ___ after proof of loss has been submitted to the insurance company',
      choices: ['30 days', '60 days', '90 days', '120 days'],
      type: 'MCQs',
      correctAnswers: ['60 days'],
      score: 1,
      additionalInfo: 'No legal action can be initiated within 60 days after proof of loss has been submitted to the insurance company.',
    },
    {
      id: '345b207f-1b33-46f7-a834-28a65dbad450',
      question: 'The open enrollment period for Medicare Supplements begins at age',
      choices: ['62', '65', '67', '70'],
      type: 'MCQs',
      correctAnswers: ['65'],
      score: 1,
      additionalInfo: 'Open enrollment for Medicare Supplements begins at the age the individual becomes eligible for Medicare, which is typically age 65.',
    },
    {
      id: '0b68b34f-f73b-40db-a71d-d9560bb79541',
      question: 'As classified by the Affordable Care Act (ACA), a Silver Plan offers',
      choices: ['60% actuarial level of coverage provided', '70% actuarial level of coverage provided', '80% actuarial level of coverage provided', '90% actuarial level of coverage provided'],
      type: 'MCQs',
      correctAnswers: ['70% actuarial level of coverage provided'],
      score: 1,
      additionalInfo: 'As classified by the Affordable Care Act (ACA), a Silver Plan offers 70% actuarial level of coverage provided.',
    },
    {
      id: '882c8a69-6779-4cb0-88b9-2173aa759b02',
      question: 'Written proof of loss must be given to the insurance company within',
      choices: ['30 days', '45 days', '60 days', '90 days'],
      type: 'MCQs',
      correctAnswers: ['90 days'],
      score: 1,
      additionalInfo: 'Written proof of loss must be given to the insurance company within 90 days.',
    },
    {
      id: '39914291-c55f-4fb0-ba99-1f2cf6171f48',
      question: 'Medicare Part A does not pay for medical benefits provided for treatment in a skilled nursing facility beyond',
      choices: ['30 days', '60 days', '100 days', '120 days'],
      type: 'MCQs',
      correctAnswers: ['100 days'],
      score: 1,
      additionalInfo: 'Medicare Part A (Hospital Insurance) will cover a maximum of 100 days per benefit period in a skilled nursing facility.',
    },
    {
      id: '2b24fbb1-9053-41c2-a871-17c30b45263f',
      question: 'Sole proprietors are permitted tax deductions for health costs paid from their earnings in the amount of',
      choices: [
        'costs that exceed 10% of AGI',
        'costs that exceed 7 1/2 % of AGI',
        'no deduction permitted',
        '100% of costs'
      ],
      type: 'MCQs',
      correctAnswers: ['100% of costs'],
      score: 1,
      additionalInfo: '100% of health costs paid by sole proprietors is tax deductible.',
    },
    {
      id: '7c7989c9-0ab1-4c5f-8b80-e0d52c5375a7',
      question: 'Lorenzo is self employed with an S corporation. He is unmarried and had a net profit for the tax year. What are the tax ramifications of his health insurance premiums paid for the year?',
      choices: [
        '50% of his health insurance costs can be deducted from his gross income',
        '75% of his health care costs can be paid with pre-tax income',
        '100% of his health insurance costs can be paid with tax credits',
        '100% of his health insurance costs can be deducted from his gross income'
      ],
      type: 'MCQs',
      correctAnswers: ['100% of his health insurance costs can be deducted from his gross income'],
      score: 1,
      additionalInfo: 'S Corporations "pass through" their taxable income and losses to owners who then report their share of profits and losses on their own individual tax return. The owner can then deduct 100% of all health insurance costs from their gross income.',
    },
    {
      id: 'd72170fd-2b27-40f8-9823-9265eaa5716f',
      question: 'A closed network plan offers a primary physician copay of $25. If a subscriber chooses a primary care physician outside of the network, the subscriber will likely pay',
      choices: ['$0', '$25', '100% of the billed amount', '100% of the allowed amount'],
      type: 'MCQs',
      correctAnswers: ['100% of the billed amount'],
      score: 1,
      additionalInfo: 'In this situation, the insured will pay 100% of the billed amount due to the physician being outside the network.',
    },
    {
      id: '7f37911f-76bd-430c-88f2-6d5e6a5b8f44',
      question: 'A policyholder has a major medical plan with a 80%/20% coinsurance and a deductible of $75. If the insured has previously met her deductible and receives a bill for $175, how much will the insurer pay?',
      choices: ['$35', '$75', '$100', '$140'],
      type: 'MCQs',
      correctAnswers: ['$140'],
      score: 1,
      additionalInfo: 'Because the insured has previously met her deductible, the eligible expenses for a claim is the entire $175. The insurance company pays 80% of $175, or $140.',
    },
    {
      id: '82bc59da-a354-4b79-a063-18e4f2ca4587',
      question: 'A temporary license is valid for a maximum of ___ days',
      choices: ['30', '60', '90', '180'],
      type: 'MCQs',
      correctAnswers: ['180'],
      score: 1,
      additionalInfo: 'A temporary license is valid for a maximum of 180 days.',
    },
    {
      id: '01dc1ece-81e2-4a21-8ba7-e7932b84e23d',
      question: 'An insured under a Major Medical expense plan with a zero deductible and 80/20 coinsurance provision files a $1,000 claim. How much of this claim is the insured responsible for?',
      choices: ['$160', '$200', '$600', '$800'],
      type: 'MCQs',
      correctAnswers: ['$200'],
      score: 1,
      additionalInfo: 'Because this policy has a zero deductible, the insured is only responsible for the 20% coinsurance on this claim, or $200.',
    },
    {
      id: 'ffb95585-cc9f-48cc-9b41-52442697a944',
      question: 'If an employee contributes 50% toward the disability plan premium provided by an employer, what would be considered the taxable income of a $1,000 monthly disability benefit?',
      choices: ['$100', '$250', '$500', '$1,000'],
      type: 'MCQs',
      correctAnswers: ['$500'],
      score: 1,
      additionalInfo: 'In this situation, the taxable monthly income is $500 because half of the premium was paid for by the employer.',
    },
    {
      id: '4419296e-85bc-4c65-ad51-5c369bae162a',
      question: 'Tonya has replaced her whole life policy with an annuity without incurring a tax penalty. This transaction is called a(n)',
      choices: ['1035 Exchange', '1040 Exchange', 'Endowment Exchange', 'Modified Exchange'],
      type: 'MCQs',
      correctAnswers: ['1035 Exchange'],
      score: 1,
      additionalInfo: 'A 1035 tax free Exchange is the IRS tax code that allows for the rollover of a non-qualified annuity (or transfer of a life insurance policy) to a new annuity or life policy of equal or greater value.',
    },
    {
      id: 'fa5a3fc8-65fc-474e-8522-9b7e4f6b5f22',
      question: 'Which of the following BEST describes a conditional insurance contract?',
      choices: [
        'A contract that requires certain conditions or acts by the insured individual',
        'A contract where one party "adheres" to the terms of the contract',
        'A contract that has the potential for the unequal exchange of consideration for both parties',
        'A contract where only one party makes any kind of enforceable contract'
      ],
      type: 'MCQs',
      correctAnswers: ['A contract that requires certain conditions or acts by the insured individual'],
      score: 1,
      additionalInfo: 'An insurance contract is conditional. This means that the insurer\'s promise to pay benefits depends on the occurrence of an event covered by the contract.',
    },
    {
      id: '5cfaa447-2fa5-459d-a41c-8587b36b9735',
      question: 'Continued coverage under COBRA would be provided to all of the following EXCEPT:',
      choices: [
        'terminated employee',
        'former dependent of employee no longer of dependent status',
        'a covered employee is terminated for gross misconduct',
        'divorced spouse of employee'
      ],
      type: 'MCQs',
      correctAnswers: ['a covered employee is terminated for gross misconduct'],
      score: 1,
      additionalInfo: 'A terminated employee is eligible to continue health coverage under COBRA unless they are terminated for gross misconduct. "Former dependent of employee no longer of dependent status" and a "divorced spouse of employee" would both be eligible for COBRA continuation as long as they were covered under the plan the day prior to divorce or losing dependent status.',
    },
    {
      id: 'bcb578e9-8a5d-4bd8-a0d6-8600d2078b81',
      question: 'A Modified Endowment Contract (MEC) is best described as',
      choices: [
        'A life insurance contract where all withdrawals prior to age 65 are subject to a 10% penalty',
        'A life insurance contract which accumulates cash values higher than the IRS will allow',
        'An annuity contract which was converted from a life insurance contract',
        'A modified life contract which enjoys all the tax advantages of whole life insurance'
      ],
      type: 'MCQs',
      correctAnswers: ['A life insurance contract which accumulates cash values higher than the IRS will allow'],
      score: 1,
      additionalInfo: 'A Modified Endowment Contract (MEC) can be described as a life insurance contract that has accumulated cash values higher than the IRS allows',
    },
    {
      id: 'da7c7f0c-a6bf-4a98-96df-91c10eaa58c1',
      question: 'How soon can the benefit payments begin with a deferred annuity?',
      choices: [
        'Anytime after date of purchase',
        'A minimum of 12 months after date of purchase',
        'Anytime within 12 months after date of purchase',
        'A minimum of 6 months after date of purchase'
      ],
      type: 'MCQs',
      correctAnswers: ['A minimum of 12 months after date of purchase'],
      score: 1,
      additionalInfo: 'A deferred annuity is an annuity contract in which periodic income payments are not scheduled to being for at least 12 months.',
    },
    {
      id: 'b8a283b2-4b55-46c4-a25f-dd28dc902f6f',
      question: 'Major medical insurance will typically cover medical expenses that result from',
      choices: [
        'a self-inflicted injury',
        'a negative reaction to prescribed medication',
        'intoxication',
        'a crime committed by the insured'
      ],
      type: 'MCQs',
      correctAnswers: ['a negative reaction to prescribed medication'],
      score: 1,
      additionalInfo: 'Medical expenses that arise from a negative reaction to prescribed medication would be typically covered by a major medical insurance policy.',
    },
    {
      id: 'a1d1e898-3904-4542-8307-3fcf9377fd83',
      question: 'All of these are valid options for an Adjustable Life Policy EXCEPT',
      choices: [
        'A non-forfeiture option can be used to increase the death benefit',
        'The policy\'s protection period can be modified',
        'The policy\'s premium can be increased or decreased',
        'The policy\'s death benefit can be increased or decreased'
      ],
      type: 'MCQs',
      correctAnswers: ['A non-forfeiture option can be used to increase the death benefit'],
      score: 1,
      additionalInfo: 'Increasing the death benefit by using one of the non-forfeiture options is not an option in an Adjustable Life Policy',
    },
    {
      id: '822bf790-05a8-4f18-a8d6-c87d909ad34a',
      question: 'Which of the following is an example of the insured\'s consideration?',
      choices: [
        'Legal purpose',
        'Insurer\'s promise to pay benefits',
        'A paid premium',
        'Intent'
      ],
      type: 'MCQs',
      correctAnswers: ['A paid premium'],
      score: 1,
      additionalInfo: 'An example of the insured\'s consideration is a paid premium',
    },
    {
      id: 'e1b37f6c-e707-45e0-8720-d5b88d1151c8',
      question: 'An agent who sells an individual life insurance policy MUST deliver to the policyowner',
      choices: [
        'An Underwriting Report Disclosure',
        'A Prospectus and Ledger Statement',
        'An Annual Financial Statement of the insurer',
        'A Policy Summary and Buyer\'s Guide'
      ],
      type: 'MCQs',
      correctAnswers: ['A Policy Summary and Buyer\'s Guide'],
      score: 1,
      additionalInfo: 'An agent who sells an individual life insurance policy MUST deliver a policy summary and buyer\'s guide to the policyowner.',
    },
    {
      id: 'f3ffbc31-e3dd-4979-b08e-e691fcba573f',
      question: 'Which of the following is NOT considered advertising?',
      choices: [
        'A rating from a rating service company, such as A.M. Best',
        'An illustration',
        'Direct mailing from an agency',
        'A sales presentation'
      ],
      type: 'MCQs',
      correctAnswers: ['A rating from a rating service company, such as A.M. Best'],
      score: 1,
      additionalInfo: 'An A.M. Best rating is not considered advertising.',
    },
    {
      id: 'ab73b787-664c-40cd-8b62-1fd03607023f',
      question: 'After collecting an insurance premium from an applicant, the producer must deliver or mail',
      choices: [
        'a Policy Summary and Buyer\'s Guide',
        'a receipt for the policy',
        'the policy',
        'a notice regarding replacement'
      ],
      type: 'MCQs',
      correctAnswers: ['a receipt for the policy'],
      score: 1,
      additionalInfo: 'After collecting an insurance premium from an applicant, the producer must deliver or mail a receipt for the policy.',
    },
    {
      id: 'f538aaf9-51a4-4a60-ae20-72ee2a7974ba',
      question: 'Replacing an existing life insurance policy with a new one may result in',
      choices: [
        'A surrender charge',
        'Capital gains taxation',
        'Small business taxation',
        'An illegal transaction'
      ],
      type: 'MCQs',
      correctAnswers: ['A surrender charge'],
      score: 1,
      additionalInfo: 'Replacing an existing life insurance policy with a new one may result in a surrender charge.',
    },
    {
      id: 'a79e6ebd-78c8-429b-8f1a-c90129ed3cde',
      question: 'Which of the following is considered to be an alternative to a life settlement?',
      choices: [
        'Extended term option',
        'Decreasing term insurance',
        'Accelerated death benefit rider',
        'Waiver of premium rider'
      ],
      type: 'MCQs',
      correctAnswers: ['Accelerated death benefit rider'],
      score: 1,
      additionalInfo: 'An alternative to a life settlement is an accelerated death benefit rider.',
    },
    {
      id: '4ae2d6b5-ddd4-431c-9136-3dc7168227a0',
      question: 'Which of these is considered to be a Living Benefit option in a life insurance policy?',
      choices: [
        'Accelerated death benefit',
        'Waiver of premium',
        'Payor benefit',
        'Reinstatement'
      ],
      type: 'MCQs',
      correctAnswers: ['Accelerated death benefit'],
      score: 1,
      additionalInfo: 'The accelerated death benefit is considered to be a Living Benefit option in a life insurance policy.',
    },
    {
      id: 'e8ed4cd1-ec32-42f2-b17b-91b5aec4bead',
      question: 'The double indemnity provision in a life insurance policy pertains to an insured\'s death caused by a(n)',
      choices: [
        'suicide',
        'accident',
        'sickness',
        'war'
      ],
      type: 'MCQs',
      correctAnswers: ['accident'],
      score: 1,
      additionalInfo: 'A life insurance policy\'s double indemnity provision would apply when the policyowner\'s death occurs due to an accident. Payment on the insurance policy will be two times the face value of the policy.',
    },
    {
      id: '3b7c8845-6a46-41d7-9747-e272b71d3f39',
      question: 'All of these are common exclusions to a life insurance policy EXCEPT',
      choices: [
        'military service',
        'hazardous occupations',
        'accidental death',
        'aviation'
      ],
      type: 'MCQs',
      correctAnswers: ['accidental death'],
      score: 1,
      additionalInfo: 'Accidental death is not a common exclusion to a life insurance policy.',
    },
    {
      id: '4d287079-9b28-464b-b771-1344dc2ca31c',
      question: 'All of these are valid policy dividend options for a life insurance policyowner EXCEPT',
      choices: [
        'reduction in policy premium',
        'accumulate without interest',
        'cash outlay to the policyowner',
        'buy additional insurance coverage'
      ],
      type: 'MCQs',
      correctAnswers: ['accumulate without interest'],
      score: 1,
      additionalInfo: 'Policyowner dividends normally accumulate WITH interest.',
    },
    {
      id: 'f64f43cf-929e-482d-86a7-1d8a46295329',
      question: 'Which dividend option would an insurer invest the policyowner\'s money and add any interest earnings as the dividends accrue?',
      choices: [
        'Accumulation at Interest Option',
        'Paid-Up Additions Option',
        'One-Year Term Dividend Option',
        'Cash Dividend Option'
      ],
      type: 'MCQs',
      correctAnswers: ['Accumulation at Interest Option'],
      score: 1,
      additionalInfo: 'The Accumulation at Interest Option invests the policyowner\'s money and adds interest earnings to the initial amount of the dividends.',
    },
    {
      id: 'a067831f-8875-48f3-b018-c27fa857bb96',
      question: 'Kathy\'s annuity is currently experiencing tax-deferred growth until she retires. Which phase is this annuity in?',
      choices: [
        'Growth period',
        'Accumulation period',
        'Payout period',
        'Deferred period'
      ],
      type: 'MCQs',
      correctAnswers: ['Accumulation period'],
      score: 1,
      additionalInfo: 'Kathy\'s annuity is in the accumulation period.',
    },
    {
      id: '89f3668d-5bb3-492b-b7ee-439eec1c5a93',
      question: 'A long-term care policy typically provides all of the following levels of care EXCEPT',
      choices: [
        'skilled care',
        'acute care',
        'custodial care',
        'intermediate care'
      ],
      type: 'MCQs',
      correctAnswers: ['acute care'],
      score: 1,
      additionalInfo: 'Acute care is not covered under long-term care policies.',
    },
    {
      id: 'cfe4ce4d-7eab-4deb-8895-7b64f31cbe9f',
      question: 'A policyowner may change two policy features on what type of live insurance?',
      choices: [
        'Whole Life',
        'Decreasing Term Life',
        'Adjustable Life',
        'Modified Whole Life'
      ],
      type: 'MCQs',
      correctAnswers: ['Adjustable Life'],
      score: 1,
      additionalInfo: 'Adjustable Life allows thee policyowner to change two policy features: premium and face amount.',
    },
    {
      id: '5f50a8f9-c8aa-4024-affe-0f2c40ba6f57',
      question: 'The Commissioner of Insurance along with the Department of Insurance are responsible for',
      choices: [
        'making insurance laws',
        'establish sales quotas',
        'hiring industry lobbyists',
        'administering Washington\'s insurance laws'
      ],
      type: 'MCQs',
      correctAnswers: ['administering Washington\'s insurance laws'],
      score: 1,
      additionalInfo: 'The Commissioner of Insurance along with the Department of Insurance are responsible for administering Washington\'s insurance laws.',
    },
    {
      id: '204d27b4-9eb6-4edb-a1c1-8f374f543e3d',
      question: 'When can an insurer cancel a Medicare supplement plan?',
      choices: [
        'At any time',
        'After nonpayment',
        'On a date specified in the policy',
        'At the enrollment period'
      ],
      type: 'MCQs',
      correctAnswers: ['After nonpayment'],
      score: 1,
      additionalInfo: 'An insurer cancel a Medicare supplement plan after nonpayment.',
    },
    {
      id: '9600ae4b-e035-47d0-b378-4376a167e3d5',
      question: 'An error was made on Mary\'s life insurance application. Which of the following areas do errors commonly occur on applications and for which the incontestable clause does NOT apply?',
      choices: [
        'Income',
        'Marital status',
        'Age',
        'Address'
      ],
      type: 'MCQs',
      correctAnswers: ['Age'],
      score: 1,
      additionalInfo: 'The incontestable clause does not apply to the misstatement of age provision.',
    },
    {
      id: 'af06f094-0230-4a52-8eca-6b66744422d4',
      question: 'A group policy may be issued to a labor union. The members eligible for insurance under the policy shall be',
      choices: [
        'healthy members of the union',
        'members of any union',
        'only members of the union that are under the age of 65',
        'all of the members of the union'
      ],
      type: 'MCQs',
      correctAnswers: ['all of the members of the union'],
      score: 1,
      additionalInfo: 'A group policy may be issued to a labor union. All of the members of the union shall be eligible for insurance under the policy.',
    },
    {
      id: '0b8ca4f3-17f3-4273-b0ad-636fedea7b2d',
      question: 'An individual most likely will have an insurable interest in insuring a person\'s life if',
      choices: [
        'a financial interest exists at the time of insured\'s death',
        'there is any blood relationship with the insured',
        'a business relationship exists',
        'an economic interest exists for the continuance of the insured\'s life'
      ],
      type: 'MCQs',
      correctAnswers: ['an economic interest exists for the continuance of the insured\'s life'],
      score: 1,
      additionalInfo: 'An individual most likely will have an insurable interest in insuring a person\'s life if an economic interest exists for the continuance of the insured\'s life.',
    },
    {
      id: '96339618-c209-4168-974e-890a37d33839',
      question: 'A change in an insurance application requires',
      choices: [
        'an initial made by the applicant',
        'an initial made by the producer',
        'approval by the insurer',
        'submitting a new application'
      ],
      type: 'MCQs',
      correctAnswers: ['an initial made by the applicant'],
      score: 1,
      additionalInfo: 'Any changes in an insurance application must be initialed by the applicant.',
    },
    {
      id: '7b319466-a3dd-44d5-9409-0c206d18e68c',
      question: 'The deeds and actions of a producer indicate what kind of authority?',
      choices: [
        'Conditional',
        'Apparent',
        'Implied',
        'Express'
      ],
      type: 'MCQs',
      correctAnswers: ['Apparent'],
      score: 1,
      additionalInfo: 'Apparent authority is the appearance or assumption of authority based on the actions, words, or deeds of the producer.',
    },
    {
      id: '249a310e-1042-47af-8c49-3d3ae332fe20',
      question: 'The entire contract includes the actual policy and the',
      choices: [
        'warranty statements',
        'inspection report',
        'application',
        'provisions'
      ],
      type: 'MCQs',
      correctAnswers: ['application'],
      score: 1,
      additionalInfo: 'The entire contract includes the actual policy and the application.',
    },
    {
      id: 'f93313c3-f613-41b5-86ed-7de38f901f12',
      question: 'In Washington, an insurance producer cannot sell, solicit, or negotiate policies for an insurer unless there is an agreement in place between the producer and insurer. This agreement is called a(n)',
      choices: [
        'binder',
        'designation',
        'license',
        'appointment'
      ],
      type: 'MCQs',
      correctAnswers: ['appointment'],
      score: 1,
      additionalInfo: 'The agreement between an insurance producer and insurer under which the insurance producer, for compensation, may sell, solicit, or negotiate policies issued by the insurer is called an appointment.',
    },
    {
      id: '639ae197-6e48-4f89-add3-f3ca18fb87e8',
      question: 'The difference between a Long Term Care Partnership Plan and a Non-Partnership Plan is which of the following?',
      choices: [
        'Pre-existing conditions',
        'Asset Protection',
        'Level of benefits',
        'Elimination period'
      ],
      type: 'MCQs',
      correctAnswers: ['Asset Protection'],
      score: 1,
      additionalInfo: 'Assets are protected from Medicaid Recovery under a Long-Term Care Partnership plan. Non-partnership plans do not protect assets from Medicaid Recovery.',
    },
    {
      id: '61a5f62c-1d18-4217-97b6-e2f8076ab9cf',
      question: 'Bill requires some nursing care and supervision but NOT full-time care. Which of these nursing home options would best serve him?',
      choices: [
        'Custodial residence',
        'Nursing home',
        'Congregate housing',
        'Assisted living'
      ],
      type: 'MCQs',
      correctAnswers: ['Assisted living'],
      score: 1,
      additionalInfo: 'An assisted living facility would best suit an individual who needs some nursing care and supervision but not full-time care.',
    },
    {
      id: '37d35890-de0b-4971-bbeb-8a33efc97c0f',
      question: 'What is implied authority defined as?',
      choices: [
        'Authority given to an agent to act outside the scope of the agency agreement',
        'Authority given to handle claims and process payments',
        'Authority that is not specifically given to an agent in the agency contract, but that an agent can reasonably assume to carry out his/her duties',
        'Authority given in writing to an agent in the agency agreement'
      ],
      type: 'MCQs',
      correctAnswers: ['Authority that is not specifically given to an agent in the agency contract, but that an agent can reasonably assume to carry out his/her duties'],
      score: 1,
      additionalInfo: 'Implied authority is defined as the authority that is not specifically granted to an agent in the agency agreement, but that an agent can reasonably assume to accomplish the day-to-day activities of the job.',
    },
    {
      id: 'b10bc67a-9670-4b5d-b336-e3507351d45f',
      question: 'No insurance company may transact business in Washington without aNo insurance company may transact business in Washington without a Certificate of ______.',
      choices: [
        'Authority',
        'Fiduciary',
        'Completion',
        'Achievement'
      ],
      type: 'MCQs',
      correctAnswers: ['Authority'],
      score: 1,
      additionalInfo: 'No insurer shall transact insurance in this state other than as authorized by a Certificate of Authority issued to it by the Commissioner.',
    },
    {
      id: '72a3047d-1d9d-4bcd-929d-56b20d5c0fc3',
      question: 'Which of these factors is NOT taken into account when determining an applicant\'s life insurance needs?',
      choices: [
        'pension',
        'automobile',
        'savings',
        'Social Security'
      ],
      type: 'MCQs',
      correctAnswers: ['automobile'],
      score: 1,
      additionalInfo: 'In the process of determining an applicant\'s life insurance needs, an insurance producer takes into account all of these factors EXCEPT an applicant\'s automobile.',
    },
    {
      id: '1d9eb41e-53e5-4744-a184-ab6890a0263b',
      question: 'Which situation would qualify an individual for receiving benefits from a qualified long-term care policy?',
      choices: [
        'Becoming injured on the job',
        'Becoming cognitively impaired (mentally ill)',
        'Becoming temporarily disabled',
        'Becoming unemployed'
      ],
      type: 'MCQs',
      correctAnswers: ['Becoming cognitively impaired (mentally ill)'],
      score: 1,
      additionalInfo: 'A qualified long-term care policy must pay benefits when an individual is cognitively impaired.',
    },
    {
      id: '83439411-2249-4d7b-83fd-209bcb266041',
      question: 'A disability income policy can prevent an insured from earning a higher income than if he/she were working by utilizing',
      choices: [
        'probationary periods',
        'deductibles',
        'elimination periods',
        'benefit limits'
      ],
      type: 'MCQs',
      correctAnswers: ['benefit limits'],
      score: 1,
      additionalInfo: 'To prevent an insured from earning a higher income than if he or she were working, disability income policies utilize benefit limits.',
    },
    {
      id: '1c24ee2a-9e51-4db6-9b83-6b826fa51569',
      question: 'A disability policyowner is injured and becomes totally disabled. The benefits pay for 2 years, starting from the date of the injury. What is this time period called?',
      choices: [
        'Probationary period',
        'Benefit period',
        'Elimination period',
        'Recurrent period'
      ],
      type: 'MCQs',
      correctAnswers: ['Benefit period'],
      score: 1,
      additionalInfo: 'The time period used in this example is called the benefit period.',
    },
    {
      id: 'a282861c-804a-4f7f-b192-0a35fd6c025c',
      question: 'An insured has a health plan that pays established amounts in accordance with a list of injuries, surgical procedures, or other losses. This list is called a',
      choices: [
        'loss chart',
        'coverage menu',
        'benefit schedule',
        'benefit summary'
      ],
      type: 'MCQs',
      correctAnswers: ['benefit schedule'],
      score: 1,
      additionalInfo: 'Benefit schedules set pre-determined limits or maximums on how much money an insured can be reimbursed for a covered loss.',
    },
    {
      id: 'b1c16ef5-6929-42a2-9534-8308a1bda4c3',
      question: 'With a scheduled dental policy, how are covered expenses paid?',
      choices: [
        'A coinsurance is normally applied to benefits paid',
        'Covered expenses are fully reimbursed to the insured',
        'Benefits are limited to a specific maximum dollar amount per procedure',
        'A deductible must be met by the insured before benefits are paid'
      ],
      type: 'MCQs',
      correctAnswers: ['Benefits are limited to a specific maximum dollar amount per procedure'],
      score: 1,
      additionalInfo: 'Benefits will be limited to a specific maximum dollar amount per procedure in a scheduled dental policy.',
    },
    {
      id: 'b4c56fc2-a651-404b-b669-da18ee9cc4b1',
      question: 'Preferred risk policies with reduced premiums are issued by insurance companies because the insured has',
      choices: [
        'worse than average mortality or morbidity experience',
        'a better ability to pay premiums over a long period of time',
        'better than average mortality or morbidity experience',
        'a higher face amount than average'
      ],
      type: 'MCQs',
      correctAnswers: ['better than average mortality or morbidity experience'],
      score: 1,
      additionalInfo: 'Insurers issue preferred risk policies with reduced premiums with the expectation of better than normal mortality or morbidity experience.',
    },
    {
      id: '1fd2ff45-4e45-4f25-98b2-a0afa506b317',
      question: 'Which of these premium payment frequencies is not typically available to a policyowner?',
      choices: [
        'Semi-annual',
        'Bi-weekly',
        'Quarterly',
        'Monthly'
      ],
      type: 'MCQs',
      correctAnswers: ['Bi-weekly'],
      score: 1,
      additionalInfo: 'A bi-weekly payment mode is normally not an option to policyowners.',
    },
    {
      id: '3bc8df14-2fb8-4798-b62d-23a0db4133a7',
      question: 'How are health plans classified according to the Affordable Care Act?',
      choices: [
        '1st Tier, 2nd Tier, 3rd Tier, 4th Tier',
        'Bronze, Silver, Gold, Platinum',
        'Standard, Above Average, Good, Superior',
        'Plan A, Plan B, Plan C, Plan D'
      ],
      type: 'MCQs',
      correctAnswers: ['Bronze, Silver, Gold, Platinum'],
      score: 1,
      additionalInfo: 'There are four tiers of "qualifying health plans" you or your employer can purchase on the exchange-Bronze, Silver, Gold, Platinum.',
    },
    {
      id: 'e0f4d39f-967b-43d4-a7fa-0db2ed740fbe',
      question: 'Insurable interest does NOT occur in which of the following relationships?',
      choices: [
        'Business owner and business client',
        'Sister and brother',
        'Business partners',
        'Parent and children'
      ],
      type: 'MCQs',
      correctAnswers: ['Business owner and business client'],
      score: 1,
      additionalInfo: 'There would not be insurable interest between a business owner and its customer.',
    },
    {
      id: '9edb649a-a9be-4cd9-83e1-b585001c7787',
      question: 'What enables applicants to compare different life insurance policies and helps them choose which policy is best for their needs?',
      choices: [
        'Policy guide',
        'Policy summary',
        'Buyer\'s summary',
        'Buyer\'s guide'
      ],
      type: 'MCQs',
      correctAnswers: ['Buyer\'s guide'],
      score: 1,
      additionalInfo: 'A Buyer\'s guide enables applicants to compare different life insurance policies and helps them choose which policy is best for their needs.',
    },
    {
      id: '78663346-4802-4e06-87ef-9ce19518e6fc',
      question: 'Donald is the primary insured of a life insurance policy and adds a children\'s term rider. What is the advantage of adding this rider?',
      choices: [
        'Coverage can be different for each child',
        'Increases the policy\'s overall cash value',
        'Can be converted to permanent coverage without evidence of insurability',
        'Premiums on this rider are not required until the limiting age is reached'
      ],
      type: 'MCQs',
      correctAnswers: ['Can be converted to permanent coverage without evidence of insurability'],
      score: 1,
      additionalInfo: 'An advantage of a children\'s term rider is the ability to convert to permanent insurance without evidence of insurability.',
    },
    {
      id: 'f6e1f38f-abb2-4740-bd58-23aadf29d5c2',
      question: 'The type of policy where the insurer can send a notice to the insured that the policy has been cancelled in the middle of the term is called',
      choices: [
        'cancelable',
        'noncancelable',
        'optionally renewable',
        'conditionally renewable'
      ],
      type: 'MCQs',
      correctAnswers: ['cancelable'],
      score: 1,
      additionalInfo: 'The renewability provision in a cancelable policy allows the insurer to cancel or terminate the policy at any time, simply by providing written notification to the insured and refunding any advance premium that has been paid.',
    },
    {
      id: 'e05fbdad-e88d-46f1-9baf-b445e55d7250',
      question: 'Which of the following is an example of replacement?',
      choices: [
        'Canceling a major medical insurance policy to purchase a disability policy',
        'Canceling a term policy to purchase a disability policy',
        'Canceling a whole life policy to purchase a term policy',
        'Canceling a term insurance policy to purchase a long-term care policy'
      ],
      type: 'MCQs',
      correctAnswers: ['Canceling a whole life policy to purchase a term policy'],
      score: 1,
      additionalInfo: 'Canceling a whole life policy to purchase a term policy is an example of replacement. Canceling a whole life policy to purchase a term policy is an example of replacement.',
    },
    {
      id: 'cb0b4057-c75a-4b8b-afcf-966e475665a8',
      question: 'Which of the following is NOT part of an insurance contract?',
      choices: [
        'Policy',
        'Application',
        'Certificate of Authority',
        'Riders'
      ],
      type: 'MCQs',
      correctAnswers: ['Certificate of Authority'],
      score: 1,
      additionalInfo: 'The Certificate of Authority allows an insurer to conduct business in a state. It is not part of an insurance contract.',
    },
    {
      id: 'd1204ae5-1a0f-4715-8dc9-e7240c843af2',
      question: 'The coverage, conditions, and limitations in the master policy of a group contract can be found in which document?',
      choices: [
        'Certificate of Authority',
        'Certificate of coverage and benefits',
        'Coverage document',
        'Consumer report'
      ],
      type: 'MCQs',
      correctAnswers: ['Certificate of coverage and benefits'],
      score: 1,
      additionalInfo: 'The certificate of coverage and benefits describes the coverage, conditions, and limitations found in the master policy of a group contract.',
    },
    {
      id: '6470dd7f-3039-4141-9237-c840eff01565',
      question: 'Which of the following is a requirement for ANY change in an insurance application?',
      choices: [
        'Change must be approved by the insurer',
        'Change must be notarized',
        'Change must be initialed by the agent',
        'Change must be initialed by the applicant'
      ],
      type: 'MCQs',
      correctAnswers: ['Change must be initialed by the applicant'],
      score: 1,
      additionalInfo: 'Any changes in an insurance application MUST be initialed by the applicant.',
    },
    {
      id: 'ddb65926-8a02-46ac-a22c-eea629f0d729',
      question: 'All of the following are included as part of a contract in the entire contract provision EXCEPT the',
      choices: [
        'changes made by the producer',
        'policy',
        'riders',
        'application'
      ],
      type: 'MCQs',
      correctAnswers: ['changes made by the producer'],
      score: 1,
      additionalInfo: 'All of these are included as part of a contract in the entire contract provision EXCEPT the changes made by the producer.',
    },
    {
      id: '5dc39cc8-ce8b-4a92-85fb-816efa8270ab',
      question: 'Jonas is a whole life insurance policyowner and would like to add coverage for his two children. Which of the following products would allow him to accomplish this?',
      choices: [
        'Child term rider',
        'Payor rider',
        'Family maintenance rider',
        'Family income rider'
      ],
      type: 'MCQs',
      correctAnswers: ['Child term rider'],
      score: 1,
      additionalInfo: 'A child term rider is level term insurance that can be added to an existing policy.',
    },
    {
      id: 'a95e5486-9b5b-4bdd-ab6b-94676e63ab07',
      question: 'Naming a contingent beneficiary as "all surviving children" is described by which term?',
      choices: [
        'Class designation',
        'Primary designation',
        'Tertiary designation',
        'Contingent designation'
      ],
      type: 'MCQs',
      correctAnswers: ['Class designation'],
      score: 1,
      additionalInfo: '"All surviving children" is an example of naming a beneficiary by class designation.',
    },
    {
      id: '902dfd89-086e-425b-bd4f-92907a3c37f6',
      question: 'A provision that allows a policyowner to temporarily give up ownership rights to secure a loan is called a(n)',
      choices: [
        'collateral assignment',
        'automatic premium loan',
        'irrevocable assignment',
        'nonforfeiture option'
      ],
      type: 'MCQs',
      correctAnswers: ['collateral assignment'],
      score: 1,
      additionalInfo: 'A collateral assignment provision allows a person to temporarily give up a portion of their ownership rights to secure a loan.',
    },
    {
      id: '8c3ff6f1-1a52-486d-82e7-8c3cf5dc4f20',
      question: 'Ownership of a life insurance policy may be temporarily transferred with a(n)',
      choices: [
        'absolute assignment',
        'transferable assignment',
        'collateral assignment',
        'beneficiary assignment'
      ],
      type: 'MCQs',
      correctAnswers: ['collateral assignment'],
      score: 1,
      additionalInfo: 'An owner of a life insurance policy may transfer ownership temporarily with a collateral assignment.',
    },
    {
      id: '039ca5e5-56bc-4aa9-a6be-c0eee11a6ea7',
      question: 'Which of the following basics is a producer\'s fiduciary responsibility?',
      choices: [
        'Maintaining a separate bank account for each insurance company the producer represents',
        'Collecting premiums and paying them to the insurance company',
        'Acting as a referee in the event of a disagreement between an insured and the insurance company',
        'Advising prospective insureds of the lowest rates available'
      ],
      type: 'MCQs',
      correctAnswers: ['Collecting premiums and paying them to the insurance company'],
      score: 1,
      additionalInfo: 'All premiums associated with personal insurance policies must be remitted to the insurance company.',
    },
    {
      id: '0df6ab46-afae-4cd4-97a5-28234c8e4240',
      question: 'Pat is insured with a life insurance policy and Karen is his primary beneficiary. They are both involved in an automobile accident where Pat dies instantly and Karen dies 5 days later. Which policy provision will protect the rights of the contingent beneficiary to receive the policy benefits?',
      choices: [
        'Common disaster clause',
        'Spendthrift clause',
        'Nonforfeiture clause',
        'Accident indemnity clause'
      ],
      type: 'MCQs',
      correctAnswers: ['Common disaster clause'],
      score: 1,
      additionalInfo: 'With a common disaster provision, a policyowner can be sure that if both the insured and the primary beneficiary die within a short period of time, the death benefits will be paid to the contingent beneficiary.',
    },
    {
      id: '77dbc421-7fc8-4e41-86f7-0d2389740b40',
      question: 'Which of the following would be considered an underwriting duty of an agent?',
      choices: [
        'Completing all applications and collecting initial premiums',
        'Accepting or declining an application',
        'Requesting medical information from the Medical Information Bureau (MIB)',
        'Assigning a risk classification'
      ],
      type: 'MCQs',
      correctAnswers: ['Completing all applications and collecting initial premiums'],
      score: 1,
      additionalInfo: 'An agent\'s underwriting duties include completing all applications and collecting initial premiums.',
    },
    {
      id: 'b8bc4181-3111-4b8f-82f6-57a7a028b93e',
      question: 'Intentional withholding of material facts that would affect an insurance policy\'s validity is called a(n)',
      choices: [
        'misrepresentation',
        'estoppel',
        'concealment',
        'adhesion'
      ],
      type: 'MCQs',
      correctAnswers: ['concealment'],
      score: 1,
      additionalInfo: 'Deliberate withholding of material facts that would affect the validity of an insurance policy or a claim under the policy is known as concealment.',
    },
    {
      id: 'd4903b89-f557-443e-b866-ff211195335a',
      question: 'In which of the following processes will the insurer oversee the insured\'s hospital stay to confirm everything is going according to schedule and that the insured will be released as planned?',
      choices: [
        'Concurrent review',
        'Congruent review',
        'Pretense review',
        'Pre-certification review'
      ],
      type: 'MCQs',
      correctAnswers: ['Concurrent review'],
      score: 1,
      additionalInfo: 'Concurrent review involves monitoring the insured\'s hospital stay to ensure everything is going according to schedule and that the insured will be released as planned.',
    },
    {
      id: 'e5831c9e-add0-49ed-b1cd-cf0083ab8565',
      question: 'A health insurance policy where the insurer has the right to terminate the policy for reasons other than the insured\'s health is called',
      choices: [
        'guaranteed renewable',
        'conditionally cancelable',
        'conditionally renewable',
        'limited renewable'
      ],
      type: 'MCQs',
      correctAnswers: ['conditionally renewable'],
      score: 1,
      additionalInfo: 'When an insurer has the right to terminate a health insurance policy for specific reasons other than the insured\'s health, the plan is described as conditionally renewable.',
    },
    {
      id: '8029377f-2531-4d29-a307-e83b73df7db9',
      question: 'Susan is insured through her Group Health Insurance plan and changed her coverage to an individual plan with the same insurer after her employment was terminated. This change is called a(n)',
      choices: [
        'exchange',
        'extension of benefits',
        'conversion',
        'crossover'
      ],
      type: 'MCQs',
      correctAnswers: ['conversion'],
      score: 1,
      additionalInfo: 'A conversion takes place when an insured individual changes his or her Group Health Insurance to an individual policy with the same insurer at the termination of employment.',
    },
    {
      id: '8c4d37f2-b546-47ee-a5ec-3ddbba0f7d05',
      question: 'Which of these is affected by the frequency of an insurance policy\'s premium payments?',
      choices: [
        'Death benefit',
        'Cost',
        'Settlement options',
        'Cash value'
      ],
      type: 'MCQs',
      correctAnswers: ['Cost'],
      score: 1,
      additionalInfo: 'The frequency of insurance premium payments affects the policy\'s cost.',
    },
    {
      id: '60666564-ff01-4561-9961-2c965e1da836',
      question: '______ is NOT an element of a valid contract.',
      choices: [
        'Countersignature',
        'Competent parties',
        'Consideration',
        'Legal'
      ],
      type: 'MCQs',
      correctAnswers: ['Countersignature'],
      score: 1,
      additionalInfo: 'A countersignature is not an element of a valid contract.',
    },
    {
      id: 'ee2f9b1b-1862-42ac-87c8-08aa5c76b2a4',
      question: 'An individual\'s waiting period for pre-existing conditions is reduced when he or she has',
      choices: [
        'A terminal illness',
        'Creditable coverage',
        'Exemption',
        'Note from the Commissioner'
      ],
      type: 'MCQs',
      correctAnswers: ['Creditable coverage'],
      score: 1,
      additionalInfo: 'An individual\'s waiting period for pre-existing conditions is reduced when he or she has creditable coverage.',
    },
    {
      id: 'a1fcc244-adf5-4c3f-80b0-02cf232e2b2b',
      question: 'Medicare provides coverage for each of the following EXCEPT',
      choices: [
        'custodial care',
        'doctor and surgeon services',
        'hospital room and board',
        'prescription drugs'
      ],
      type: 'MCQs',
      correctAnswers: ['custodial care'],
      score: 1,
      additionalInfo: 'Custodial care is not a covered expense under Medicare.',
    },
    {
      id: 'cef1f6e6-185e-497b-9d7e-3b0b5d715038',
      question: 'An example of a presumptive disability would be',
      choices: [
        'diabetes',
        'a stroke',
        'cancer',
        'deafness'
      ],
      type: 'MCQs',
      correctAnswers: ['deafness'],
      score: 1,
      additionalInfo: 'Deafness is an example of a presumptive disability.',
    },
    {
      id: '106672f4-6b0c-4d4b-81a3-319bfeb4f7e3',
      question: 'In what part of an insurance policy are policy benefits found?',
      choices: [
        'Entire contract',
        'Conditions',
        'Waivers',
        'Declarations'
      ],
      type: 'MCQs',
      correctAnswers: ['Declarations'],
      score: 1,
      additionalInfo: 'Essentially, the insurance declaration page is like a quick guide to the insurance policy, providing all of the basic information the policyholder needs to know.',
    },
    {
      id: '90c542a8-da8c-4111-94d6-e61dcd768fe5',
      question: 'Julie has a $100,000 30-year mortgage on her new home. What type of life insurance could she purchase that is designed to pay off the loan balance if she dies within the 30-year period?',
      choices: [
        'Decreasing term insurance',
        'Adjustable life insurance',
        'Increasing term insurance',
        'Modified life insurance'
      ],
      type: 'MCQs',
      correctAnswers: ['Decreasing term insurance'],
      score: 1,
      additionalInfo: 'Decreasing term insurance is normally used to pay off a mortgage balance in the event of death of the insured.',
    },
    {
      id: '1d978f5e-4c5a-4ad9-900c-3748b270e1c9',
      question: 'All of the following are core benefits of Medicare Supplement Plan A EXCEPT',
      choices: [
        'Deductible payments for the first 60 days of hospitalization under Medicare hospitalization insurance',
        'Medicare Part A hospice coinsurance or copayment',
        'Medicare Part B coinsurance or copayment',
        'The first three pints of blood received'
      ],
      type: 'MCQs',
      correctAnswers: ['Deductible payments for the first 60 days of hospitalization under Medicare hospitalization insurance'],
      score: 1,
      additionalInfo: 'Medicare Supplement Plan A does NOT cover the Medicare Part A deductible. However, the remaining Medicare Supplement plans cover 50-100% of this cost.',
    },
    {
      id: '303c3da2-132f-4f9e-a3b3-52ca63c0a68e',
      question: 'Jennifer is required to pay a specific sum out of pocket before any benefits are paid in a year. Her health policy most likely contains a(n)',
      choices: [
        'copayment',
        'stop-loss provision',
        'out of pocket provision',
        'deductible'
      ],
      type: 'MCQs',
      correctAnswers: ['deductible'],
      score: 1,
      additionalInfo: 'A deductible is a stated initial dollar amount that the individual insured is required to pay before insurance benefits are paid.',
    },
    {
      id: 'bd3fb4ba-1eba-459a-bb7f-638e9dd355e6',
      question: 'Publishing information that is false and maliciously critical to an insurer and intended to cause injury is a prohibited practice known as',
      choices: [
        'defamation',
        'boycott',
        'coercion',
        'unfair discrimination'
      ],
      type: 'MCQs',
      correctAnswers: ['defamation'],
      score: 1,
      additionalInfo: 'Publishing information that is false and maliciously critical to an insurer and intended to cause injury is a prohibited practice known as defamation.',
    },
    {
      id: '24435b0b-7397-4145-918e-f811d7f92260',
      question: 'An example of a tax-qualified retirement plan would be a(n)',
      choices: [
        'defined contribution plan',
        'equity compensation plan',
        'executive index plan',
        '1035 exchange plan'
      ],
      type: 'MCQs',
      correctAnswers: ['defined contribution plan'],
      score: 1,
      additionalInfo: 'A defined contribution plan is considered a tax-qualified plan.',
    },
    {
      id: 'fd6f84dc-3fd0-492e-b926-ab438f55893b',
      question: 'A form of medical health insurance covering the treatment and care of gum disease is called',
      choices: [
        'Dental expense insurance',
        'Major Medical',
        'Medicaid',
        'Franchise health policy'
      ],
      type: 'MCQs',
      correctAnswers: ['Dental expense insurance'],
      score: 1,
      additionalInfo: 'Dental expense insurance is a form of medical health insurance covering the treatment and care of gum disease.',
    },
    {
      id: '287a3f8c-dfe4-43be-96f5-f8d8916c4c46',
      question: 'A hospital or medical expense policy will typically cover dental treatment expenses under which circumstance?',
      choices: [
        'After the annual limit of a dental policy\'s coverage is reached',
        'Cosmetic dental treatment is performed',
        'If treatment is performed at a in-network provider',
        'Dental treatment is needed to repair an injury'
      ],
      type: 'MCQs',
      correctAnswers: ['Dental treatment is needed to repair an injury'],
      score: 1,
      additionalInfo: 'Dental treatment expenses required to repair an injury would normally be covered under a hospital or medical expense policy.',
    },
    {
      id: '0f74f4e0-9251-4acc-afdb-cc3423033562',
      question: 'A Medicare Supplement Policy is',
      choices: [
        'designed to fill in the gaps of Part A and Part B Medicare',
        'a supplement to Medicare Advantage Part C',
        'government insurance designed to provide healthcare to the elderly',
        'designed to provide prescription drug coverage to the elderly'
      ],
      type: 'MCQs',
      correctAnswers: ['designed to fill in the gaps of Part A and Part B Medicare'],
      score: 1,
      additionalInfo: 'A Medicare Supplement policy is designed to fill in the gaps of Part A and Part B Medicare.',
    },
    {
      id: '1b8a9564-ac42-4040-a971-f4e3d0468aca',
      question: 'What is the purpose of the U.S.A. Patriot Act?',
      choices: [
        'Detect and deter misrepresentations',
        'Detect and deter terrorism',
        'Detect and deter alien insurance companies',
        'Detect and deter fraud'
      ],
      type: 'MCQs',
      correctAnswers: ['Detect and deter terrorism'],
      score: 1,
      additionalInfo: 'The purpose of the U.S.A. Patriot Act is to detect and deter terrorism. The Act imposes anti-money laundering on financial institutions such as life insurance companies.',
    },
    {
      id: '584efb9d-10da-4ce3-beea-245885a42dda',
      question: 'What is the primary purpose of a rating service company such as A.M. Best?',
      choices: [
        'Determine which insurer offers the best rates',
        'Determine financial strength of an insurance company',
        'Determine which insurer offers the best policies',
        'Determine which agent to use locally'
      ],
      type: 'MCQs',
      correctAnswers: ['Determine financial strength of an insurance company'],
      score: 1,
      additionalInfo: 'The primary purpose of a rating service company is to determine the financial strength of the company being rated.',
    },
    {
      id: 'f005361f-8b9e-4c11-a101-656082eae70e',
      question: 'Matt is applying for life insurance and requests a double indemnity rider. A double indemnity benefit will be payable to Matt\'s beneficiary if Matt',
      choices: [
        'dies instantly from a car accident',
        'is killed while committing a felony',
        'is injured in a skiing accident and dies 18 months later',
        'dies of a stroke'
      ],
      type: 'MCQs',
      correctAnswers: ['dies instantly from a car accident'],
      score: 1,
      additionalInfo: 'Matt\'s beneficiary will be provided with the double indemnity rider if Matt dies instantly from a car accident.',
    },
    {
      id: '8bce34f3-27a3-43cf-bb54-dfbb0b0d259c',
      question: 'All of these are standard exclusions found in a life insurance policy EXCEPT',
      choices: [
        'war',
        'aviation',
        'hazardous occupations',
        'disability'
      ],
      type: 'MCQs',
      correctAnswers: ['disability'],
      score: 1,
      additionalInfo: 'Disability is not one of the standard exclusions found in life insurance policies.',
    },
    {
      id: 'eb174011-9881-4cc7-b9a7-fd2897221550',
      question: 'All of these are typically sources of underwriting information for life or health insurance EXCEPT',
      choices: [
        'Attending physician\'s statement (APS)',
        'Consumer reports',
        'Medical Information Bureau (MIB) reports',
        'Disclosure authorization response'
      ],
      type: 'MCQs',
      correctAnswers: ['Disclosure authorization response'],
      score: 1,
      additionalInfo: 'A disclosure authorization response is not considered a source of underwriting information.',
    },
    {
      id: 'ca5e2e6c-7766-4c6b-8452-2e8ca68860c2',
      question: 'A Medicare Supplement policy must NOT contain benefits which',
      choices: [
        'duplicate Medicare benefits',
        'charge additional premiums',
        'cover more than Medicare coverage',
        'are covered by Workers Compensation'
      ],
      type: 'MCQs',
      correctAnswers: ['duplicate Medicare benefits'],
      score: 1,
      additionalInfo: 'A Medicare Supplement policy must not contain benefits which duplicate Medicare benefits.',
    },
    {
      id: '15a1ce3c-45de-4072-8491-2a80971bb258',
      question: 'Lisa has recently bought a fixed annuity. Which of these is considered to be a disadvantage of owning this type of annuity?',
      choices: [
        'Payments cease 5 years after the annuitant\'s death',
        'Payment amounts can be unpredictable from month to month',
        'During periods or inflation, annuitants wil experience an increase in purchasing power of their payments',
        'During periods of inflation, annuitants will experience a decrease in purchasing power of their payments'
      ],
      type: 'MCQs',
      correctAnswers: ['During periods of inflation, annuitants will experience a decrease in purchasing power of their payments'],
      score: 1,
      additionalInfo: 'The disadvantage of fixed annuities is annuitants could experience a decrease in the purchasing power of their payments over a period of years due to inflation.',
    },
    {
      id: '114c9198-248d-49f0-9e64-e60f835d8349',
      question: 'Under group health insurance, a certificate of coverage is issued to the',
      choices: [
        'producer',
        'employee',
        'employer',
        'sponsor'
      ],
      type: 'MCQs',
      correctAnswers: ['employee'],
      score: 1,
      additionalInfo: 'Under group health insurance, a certificate of coverage is issued to the employee that outlines their benefits. The employer is issued the master contract and considered the policyowner.',
    },
    {
      id: '89657634-a0e5-48a3-982c-8074f8970c04',
      question: 'Key Person Disability Insurance pays benefits to the',
      choices: [
        'hospital',
        'employer',
        'employee',
        'emplovee\'s creditors'
      ],
      type: 'MCQs',
      correctAnswers: ['employer'],
      score: 1,
      additionalInfo: 'Key Person Disability Insurance provides crucial benefits to protect the company financially in the event that a key employee can no longer work due to a disability. Key Person coverage provides cash flow to help companies move forward and maintain a profit in the event that a key employee becomes disabled. The employer is the policyowner and pays premiums that are NOT tax-deductible. Benefits, however, are received tax-free to the employer.',
    },
    {
      id: 'db9b6c2b-9ded-4b50-b8b8-2f6e502aa673',
      question: 'Which of the following would be considered a possible applicant and contract policyholder for group health benefits?',
      choices: [
        'Human resource department',
        'Insurance company',
        'Insured employee',
        'Employer'
      ],
      type: 'MCQs',
      correctAnswers: ['Employer'],
      score: 1,
      additionalInfo: 'An employer would be a possible applicant and contract policyholder of a single master contract or master policy for group health benefits.',
    },
    {
      id: '108f67e6-088e-4cfc-a10e-ea1c17d837f1',
      question: 'The Commissioner of Insurance is empowered to',
      choices: [
        'write policy forms for mandatory insurance coverages',
        'set rates for mandatory insurance coverages',
        'enforce state laws relating to insurance',
        'pass state laws relating to insurance'
      ],
      type: 'MCQs',
      correctAnswers: ['enforce state laws relating to insurance'],
      score: 1,
      additionalInfo: 'The Commissioner of Insurance is empowered to enforce state laws relating to insurance.',
    },
    {
      id: '4a96ca5c-15d2-41d3-9575-32c739a1f2d4',
      question: 'What provision states that the policy and the application shall constitute the entire contract between the parties?',
      choices: [
        'Consideration',
        'Whole contract',
        'Entire contract',
        'Estoppel'
      ],
      type: 'MCQs',
      correctAnswers: ['Entire contract'],
      score: 1,
      additionalInfo: 'The Entire Contract provision states that the policy and the application shall constitute the entire contract between the parties.',
    },
    {
      id: 'b565c0ad-aa91-4144-9808-bc633a221c35',
      question: 'Level premium permanent insurance accumulates a reserve that will eventually',
      choices: [
        'equal the face amount of the policy',
        'require the policyowner to make periodic withdrawals',
        'become larger than the face amount',
        'pay a dividend to the policyownerpay a dividend to the policyowner'
      ],
      type: 'MCQs',
      correctAnswers: ['equal the face amount of the policy'],
      score: 1,
      additionalInfo: 'Level premium permanent insurance accumulates a reserve that will eventually equal the face amount of the policy.',
    },
    {
      id: '0a03144e-be44-42fa-9b67-f85bd4e5a8c5',
      question: 'Which of the following is an annuity that is linked to a market-related index?',
      choices: [
        'Market-related annuity',
        'Equity-indexed annuity',
        'Deferred-risk annuity',
        'Fixed-amount annuity'
      ],
      type: 'MCQs',
      correctAnswers: ['Equity-indexed annuity'],
      score: 1,
      additionalInfo: 'An equity-indexed annuity is linked to a market-related index.',
    },
    {
      id: '73453469-2ef2-4620-a521-edaf0dfee04b',
      question: 'A professional liability for which producers can be sued for mistakes of putting a policy into effect is called',
      choices: [
        'errors and oversights',
        'fiduciary trust',
        'errors and omissions',
        'fiduciary bond'
      ],
      type: 'MCQs',
      correctAnswers: ['errors and omissions'],
      score: 1,
      additionalInfo: 'Errors and omissions is a professional liability for which producers can be sued for mistakes of putting a policy into effect.',
    },
    {
      id: 'c3674cd0-7a65-4cda-b07e-0599f38184b6',
      question: 'Craig purchased a life insurance policy for enabling his heirs to pay estate taxes. What is this called?',
      choices: [
        'Estate conservation',
        'Survivor fund',
        'Liquidity maintenance',
        'Human value protection'
      ],
      type: 'MCQs',
      correctAnswers: ['Estate conservation'],
      score: 1,
      additionalInfo: 'When individuals purchase life insurance to enable their heirs to pay estate taxes, this is called estate conservation.',
    },
    {
      id: 'bc61733d-4c9a-44e4-9d6a-b519f9ce2aa5',
      question: 'What is created after policy proceeds are obtained in a lump sum and then immediately invested?',
      choices: [
        'Emergency Fund',
        'Estate',
        'Lump Sum Fund',
        'Viatical Settlement'
      ],
      type: 'MCQs',
      correctAnswers: ['Estate'],
      score: 1,
      additionalInfo: 'Policy proceeds can be obtained in a lump sum and invested to create an estate.',
    },
    {
      id: '6ee3d7c3-fba5-4d19-b800-dde0454dfa48',
      question: 'Under a non-qualified annuity, interest is taxed after the',
      choices: [
        'exclusion ratio has been calculated',
        'deposits have been made',
        'distribution of payments',
        'death of the annuitant'
      ],
      type: 'MCQs',
      correctAnswers: ['exclusion ratio has been calculated'],
      score: 1,
      additionalInfo: 'The taxable and non-taxable portions of annuity payments are determined by the exclusion ratio.',
    },
    {
      id: 'b2bc46ed-1435-4b70-ad7a-805c8262c886',
      question: 'Which of these would limit a company\'s liability to provide insurance coverage?',
      choices: [
        'Rider',
        'Waiver',
        'Exclusion',
        'Provision'
      ],
      type: 'MCQs',
      correctAnswers: ['Exclusion'],
      score: 1,
      additionalInfo: 'An exclusion is a condition which limits the company\'s liability to provide coverage.',
    },
    {
      id: '30563edf-c2da-467e-9599-071d4391590c',
      question: 'One of the most important considerations when replacing health insurance would be the',
      choices: [
        'Exclusions on a new policy',
        'Cost',
        'Age of the insured',
        'Occupation of the insured'
      ],
      type: 'MCQs',
      correctAnswers: ['Exclusions on a new policy'],
      score: 1,
      additionalInfo: 'One of the most important considerations in replacing medical insurance is the exclusions or limitations on a new policy.',
    },
    {
      id: '192c5260-ca74-4392-91bd-3ad326e53971',
      question: 'Fraternal Benefit Society has each of the following characteristics EXCEPT',
      choices: [
        'Incorporated',
        'Exist for the benefit of its members',
        'Without capital stock',
        'Exist For profit'
      ],
      type: 'MCQs',
      correctAnswers: ['Exist For profit'],
      score: 1,
      additionalInfo: 'Fraternal Benefit Societies are not-for-profit entities which exist for the benefit of its members.',
    },
    {
      id: '206af7c2-f1be-4f65-8bcd-2532e2ab174e',
      question: 'All of these are settlement options for life insurance policies EXCEPT',
      choices: [
        'Life income',
        'Lump sum',
        'Extended term',
        'Fixed period'
      ],
      type: 'MCQs',
      correctAnswers: ['Extended term'],
      score: 1,
      additionalInfo: 'The extended term option is a non-forfeiture option, not a settlement option.',
    },
    {
      id: 'd708da1d-51a0-4bad-994a-a6e011881fcb',
      question: 'Which of the following acts by an insurer, if committed without just cause and performed with such frequency as to indicate standard operating procedures, constitutes an Unfair Claims Settlement Practice?',
      choices: [
        'Discouraging policyowners from initiating lawsuits by offering a quick and fair claim settlement',
        'Failing to adopt and implement reasonable standards for the prompt investigations of claims',
        'Investigating a claim without the knowledge and permission of the claimant',
        'Unknowingly misrepresenting pertinent facts or policy provisions to claimants'
      ],
      type: 'MCQs',
      correctAnswers: ['Failing to adopt and implement reasonable standards for the prompt investigations of claims'],
      score: 1,
      additionalInfo: 'Failing to adopt and implement reasonable standards for the prompt investigations of claims is considered to be an unfair claims settlement.',
    },
    {
      id: '76d235d3-d4a9-40f3-ad17-586e55f0416a',
      question: 'An insurance company needs to obtain personal information from a third party concerning an applicant. Which law do all insurers and their producers need to comply with?',
      choices: [
        'McCarran-Ferguson Act',
        'Personal Information Act',
        'USA Patriot Act',
        'Fair Credit Reporting Act'
      ],
      type: 'MCQs',
      correctAnswers: ['Fair Credit Reporting Act'],
      score: 1,
      additionalInfo: 'To protect the rights of consumers for whom an inspection or credit report has been requested, Congress enacted the Fair Credit Reporting Act in 1970.',
    },
    {
      id: '2892a90d-2cda-4eea-befe-2ca1dd2a54a9',
      question: 'A life insurance policy normally contains a provision that restricts coverage in the event of death under all of the following situations EXCEPT',
      choices: [
        'war',
        'pilot of personal airplane',
        'fare-paying passenger',
        'suicide'
      ],
      type: 'MCQs',
      correctAnswers: ['fare-paying passenger'],
      score: 1,
      additionalInfo: 'A policy may contain provisions excluding or restricting coverage as specified in the event of death under all of these situations EXCEPT a fare-paying passenger.',
    },
    {
      id: '8c275556-a66b-46ba-9545-83d7883a586a',
      question: 'A payment system for health care in which the provider is paid for each service given is called',
      choices: [
        'a blanket plan',
        'lump sum',
        'fee-for-service',
        'a prepaid arrangement'
      ],
      type: 'MCQs',
      correctAnswers: ['fee-for-service'],
      score: 1,
      additionalInfo: 'A fee-for-service payment system is one where the provider is paid for each service given.',
    },
    {
      id: '514bce50-a628-4f1f-b469-61a8f992ba49',
      question: 'Elizabeth is the beneficiary of a life insurance policy. She is receiving the death benefit in payments of $10,000 per month until the principal and interest has been paid out. Which option was chosen?',
      choices: [
        'Fixed period',
        'Fixed amount',
        'Interest only',
        'Life income'
      ],
      type: 'MCQs',
      correctAnswers: ['Fixed amount'],
      score: 1,
      additionalInfo: 'The fixed amount installment option pays a fixed death benefit in specified installment amounts until the principal and interest are exhausted.',
    },
    {
      id: 'deae842d-4db7-4b41-9055-461ac7c04bcd',
      question: 'All of these are characteristics of a universal life insurance policy EXCEPT',
      choices: [
        'Fixed surrender value',
        'Builds cash value',
        'Flexible premiums',
        'Flexible death benefit'
      ],
      type: 'MCQs',
      correctAnswers: ['Fixed surrender value'],
      score: 1,
      additionalInfo: 'A fixed surrender value is NOT a characteristic of a universal life insurance policy.',
    },
    {
      id: '639ebbfb-9b64-4284-aa21-42fb544d3536',
      question: 'Which of these annuities require premium payments that vary from year to year?',
      choices: [
        'Fixed premium deferred annuity',
        'Fixed premium immediate annuity',
        'Flexible premium immediate annuity',
        'Flexible premium deferred annuity'
      ],
      type: 'MCQs',
      correctAnswers: ['Flexible premium deferred annuity'],
      score: 1,
      additionalInfo: 'Flexible premium deferred annuities require premium payments that vary from year to year.',
    },
    {
      id: '7c039a64-ad04-4e45-840d-d785ef6ec7db',
      question: 'A Medicare supplement plan can be canceled by the insurer',
      choices: [
        'anytime',
        'after the probation period',
        'for nonpayment of premiums',
        'for any changes in the insured\'s health'
      ],
      type: 'MCQs',
      correctAnswers: ['for nonpayment of premiums'],
      score: 1,
      additionalInfo: 'An insurer can cancel a Medicare supplement plan after nonpayment of premiums.',
    },
    {
      id: 'a533a4f3-9fcb-4c8c-b1f8-6657a3ecbbf9',
      question: 'An association operating solely for the benefit of its members and their beneficiaries is called a',
      choices: [
        'Paternal benefit society',
        'Fraternal benefit society',
        'Fraternity benefit society',
        'Maternal benefit society'
      ],
      type: 'MCQs',
      correctAnswers: ['Fraternal benefit society'],
      score: 1,
      additionalInfo: 'A Fraternal benefit society is an association operating solely for the benefit of its members and their beneficiaries.',
    },
    {
      id: 'f06827b0-1dd1-42ad-ae67-62b3a809ea37',
      question: 'Premium mode is a term used to describe the',
      choices: [
        'frequency of the premium payment',
        'method of payment',
        'premium paid',
        'premium past due'
      ],
      type: 'MCQs',
      correctAnswers: ['frequency of the premium payment'],
      score: 1,
      additionalInfo: 'The premium mode defines the frequency of the premium payment.',
    },
    {
      id: '63c85f00-de30-475d-841f-a35f1e19421d',
      question: 'Tom has a home health care benefit and is confined to his home. Which of these benefits is NOT typically covered?',
      choices: [
        'Home health aides',
        'Part-time nursing care',
        'Full-time nursing care',
        'Physical therapy'
      ],
      type: 'MCQs',
      correctAnswers: ['Full-time nursing care'],
      score: 1,
      additionalInfo: 'Home health care is care provided in the insured\'s home, usually on a part-time basis.',
    },
    {
      id: 'b797d6d0-e26c-42a8-9b15-bb5ae3ca07b0',
      question: 'A group Disability Income plan that pays tax free benefits to covered employees is considered',
      choices: [
        'partially contributory',
        'non-contributory',
        'fully contributory',
        'group contributory'
      ],
      type: 'MCQs',
      correctAnswers: ['fully contributory'],
      score: 1,
      additionalInfo: 'Fully contributory group plans require the employee to pay all premium cost, therefore, any benefits received b covered employees are received tax-free.',
    },
    {
      id: '16da9eec-23c5-4e35-9749-f3daaa37766e',
      question: 'When a qualified plan starts making payments to its recipient, which portion of the distributions is taxable?',
      choices: [
        'Principal',
        'Contributions made by employer',
        'Contributions made by employee',
        'Gains'
      ],
      type: 'MCQs',
      correctAnswers: ['Gains'],
      score: 1,
      additionalInfo: 'Gains are the taxable portion of the distributions of qualified plans.',
    },
    {
      id: 'f2623f66-1ae4-43fd-9773-afc6b54abe38',
      question: 'Which of these will typically authorize treatment from a specialist?',
      choices: [
        'Policyowner',
        'Insurance company',
        'Gatekeeper',
        'Administrator'
      ],
      type: 'MCQs',
      correctAnswers: ['Gatekeeper'],
      score: 1,
      additionalInfo: 'A gatekeeper must authorize treatment from a specialist.',
    },
    {
      id: '04f5959d-1aa2-4f97-b7a4-c4f299c1ad2d',
      question: 'How is Medicare Part B funded?',
      choices: [
        'Employer taxes',
        'User premiums',
        'General tax revenue and user premiums',
        'Payroll taxes'
      ],
      type: 'MCQs',
      correctAnswers: ['General tax revenue and user premiums'],
      score: 1,
      additionalInfo: 'Medicare Part B insurance is funded by general tax revenue and user premiums.',
    },
    {
      id: '08594fb7-22ae-406b-a906-21eb17a56f13',
      question: 'Underwriters can acquire information from all of the following sources EXCEPT',
      choices: [
        'attending physician\'s statements',
        'genetic testing',
        'Medical Information Bureau (MIB)',
        'consumer reports'
      ],
      type: 'MCQs',
      correctAnswers: ['genetic testing'],
      score: 1,
      additionalInfo: 'Underwriters can acquire information from all of these sources EXCEPT genetic testing.',
    },
    {
      id: 'e15464c3-1fc6-4599-a1a4-69f05bd07f9c',
      question: 'Under the Affordable Care Act, which of these plans is designed to provide coverage that is actuarially equivalent to 80% of the full actuarial value of the benefits provided under the plan?',
      choices: [
        'Bronze',
        'Silver',
        'Platinum',
        'Gold'
      ],
      type: 'MCQs',
      correctAnswers: ['Gold'],
      score: 1,
      additionalInfo: 'A plan is designed to provide coverage that is actuarially equivalent to 80% of the full actuarial value of the benefits provided under the plan is considered a Gold Plan.',
    },
    {
      id: 'a1fa34ce-55aa-4d71-ae1f-1b3c6de4166f',
      question: 'A business will typically use which type of life insurance to cover their employees?',
      choices: [
        'Adjustable life policy',
        'Whole life policy',
        'Group policy',
        'Endowment policy'
      ],
      type: 'MCQs',
      correctAnswers: ['Group policy'],
      score: 1,
      additionalInfo: 'A type of life insurance policy most commonly used by businesses for employees is a group policy.',
    },
    {
      id: '0a7119a3-7171-45c3-8d4d-fa72b69d4658',
      question: 'A master contract and certificate of coverage can be found in which type of policy?',
      choices: [
        'Long-term',
        'Medicare',
        'Medicaid',
        'Group'
      ],
      type: 'MCQs',
      correctAnswers: ['Group'],
      score: 1,
      additionalInfo: 'A group policy has a master contract and certificate of coverage.',
    },
    {
      id: 'e6dfd77e-e3c3-448a-a9b8-9ccabbf1a374',
      question: 'Bryce purchased a disability income policy with a rider that guarantees him the option of purchasing additional amounts of coverage at predetermined times without requiring to provide evidence of insurability. What kind of rider is this?',
      choices: [
        'Extended insurability rider',
        'Additional coverage rider',
        'Guaranteed insurability rider',
        'Paid-up option rider'
      ],
      type: 'MCQs',
      correctAnswers: ['Guaranteed insurability rider'],
      score: 1,
      additionalInfo: 'A guaranteed insurability rider guarantees the insured the option of purchasing additional amounts of disability income coverage at predetermined times without requiring the insured to provide evidence of insurability.',
    },
    {
      id: 'c4c3b1a4-558e-4e04-8ad2-0ecd32d1bd51',
      question: 'An annuitant is guaranteed to NOT outlive their benefits with a(n)',
      choices: [
        'Survivorship clause',
        'Period certain provision',
        'Guaranteed lifetime withdrawal benefit',
        'Guaranteed minimum accumulation benefit'
      ],
      type: 'MCQs',
      correctAnswers: ['Guaranteed lifetime withdrawal benefit'],
      score: 1,
      additionalInfo: 'A guaranteed lifetime withdrawal benefit (GLWB) is a rider on a variable annuity that allows minimum withdrawals from the invested amount without having to annuitize the investment. This rider guarantees that a certain percentage (often based on age) of the amount invested can be withdrawn each year for as long as the contract holder lives.',
    },
    {
      id: 'd166b486-9a48-4520-a684-7472910bf7cb',
      question: 'Phil is shopping for an annuity that guarantees he CANNOT outlive the benefits. Which of these benefit options would he choose?',
      choices: [
        'Guaranteed minimum accumulation benefit',
        'Accelerated lifetime benefit',
        'Guaranteed lifetime withdrawal benefit',
        'Right of income rider'
      ],
      type: 'MCQs',
      correctAnswers: ['Guaranteed lifetime withdrawal benefit'],
      score: 1,
      additionalInfo: 'The guaranteed lifetime withdrawal benefit normally requires a fee, but it ensures that the income benefit will be paid for life without actually annuitizing the contract.',
    },
    {
      id: '9b2264c5-0892-4a7e-ba97-de47a84db80b',
      question: 'A health insurance policy that allows an insurer to change the policyowner\'s premiums, but NOT cancel the policy is called a(n)',
      choices: [
        'optionally renewable policy',
        'conditionally renewable policy',
        'noncancelable policy',
        'guaranteed renewable policy'
      ],
      type: 'MCQs',
      correctAnswers: ['guaranteed renewable policy'],
      score: 1,
      additionalInfo: 'An insurer has the right to change the premium for policyowners with a guaranteed renewable policy, but CANNOT cancel the policy.',
    },
    {
      id: 'f70380e4-5336-4128-86d5-899d4fa60e01',
      question: 'Index whole life insurance contains a securities component that acts as a(n)',
      choices: [
        'means to lowering taxes on earnings',
        'incentive to purchase more coverage',
        'premium stabilizer',
        'hedge against inflation'
      ],
      type: 'MCQs',
      correctAnswers: ['hedge against inflation'],
      score: 1,
      additionalInfo: 'The securities component of index whole life insurance is considered an effective hedge against inflation.',
    },
    {
      id: '1bfda01c-ad44-4356-bbff-911ffb986e88',
      question: 'Which approach predicts a person\'s earning potential and determines how much of that amount would be devoted to dependents?',
      choices: [
        'Future value approach',
        'Human life value approach',
        'Needs approach',
        'Earnings approach'
      ],
      type: 'MCQs',
      correctAnswers: ['Human life value approach'],
      score: 1,
      additionalInfo: 'The human life value approach predicts an individual\'s future earning potential and determines how much of that amount would be devoted to dependents.',
    },
    {
      id: '3d69ded5-3f2d-4db9-9002-e998ce93d3c3',
      question: 'Which of these is a method of determining the level of funds required for ongoing support in the event of the breadwinner\'s death?',
      choices: [
        'Human life value',
        'Assessment value',
        'Financial loss value',
        'Replacement value'
      ],
      type: 'MCQs',
      correctAnswers: ['Human life value'],
      score: 1,
      additionalInfo: 'The human life value calculator helps you assess the financial loss your family would incur if you were to die today.',
    },
    {
      id: 'd2d6cf8b-abf9-463c-aa3b-575283f8bb5e',
      question: 'What does the Consolidated Omnibus Budget Reconciliation Act (COBRA) of 1985 allow an employee to do?',
      choices: [
        'In the event of employment termination, group health insurance can be kept if the employee pays the premiums',
        'In the event of employment termination, group health insurance can be kept if the employer pays the premiums',
        'Receive a tax credit to help offset the cost of health insurance',
        'Remain on their current coverage for 30 months'
      ],
      type: 'MCQs',
      correctAnswers: ['In the event of employment termination, group health insurance can be kept if the employee pays the premiums'],
      score: 1,
      additionalInfo: 'The Consolidated Omnibus Budget Reconciliation Act (COBRA) of 1985 allows an employee to continue group medical insurance by self-paying the premiums after employment has been terminated.',
    },
    {
      id: '2d5c6d6b-929d-4158-b553-66c518133f88',
      question: 'Which of these is considered a major tax advantage of life insurance?',
      choices: [
        'Annual earnings are tax free',
        'Tax credits are available for life insurance premiums paid',
        'Income tax is typically not owed on proceeds paid directly to a beneficiary',
        'Premiums are tax deductible by an employee if paid for by an employer'
      ],
      type: 'MCQs',
      correctAnswers: ['Income tax is typically not owed on proceeds paid directly to a beneficiary'],
      score: 1,
      additionalInfo: 'One of the major tax advantages of life insurance is that the beneficiary generally does not pay income tax on the proceeds.',
    },
    {
      id: '8ddd28a3-3b74-4a7e-abc5-85c5c45a693d',
      question: 'Mike has inherited his father\'s traditional IRA. As beneficiary, he will pay ______ taxes on any money withdrawn.',
      choices: [
        'estate',
        'income',
        'probate',
        'по'
      ],
      type: 'MCQs',
      correctAnswers: ['income'],
      score: 1,
      additionalInfo: 'The correct answer is "income". When a beneficiary inherits a traditional IRA, an income tax is paid when money is withdrawn.',
    },
    {
      id: '670b139d-97e5-4f67-a32e-a29424316411',
      question: 'Which type of beneficiary should be named if the insured wants to give explicit directions on how the policy proceeds should be paid?',
      choices: [
        'Class',
        'Individual',
        'Group',
        'Estate'
      ],
      type: 'MCQs',
      correctAnswers: ['Individual'],
      score: 1,
      additionalInfo: 'An individual beneficiary designation would be most appropriate in this situation.',
    },
    {
      id: '753c8254-6197-4647-95ef-d25c123b0948',
      question: 'No insurer, producer, broker, solicitor, or person, in connection with an insurance transaction shall offer or promise to buy, sell, give, or allow the prospective insured employment, stock or special favors. This is the definition of',
      choices: [
        'inducement',
        'enticement',
        'churning',
        'twisting'
      ],
      type: 'MCQs',
      correctAnswers: ['inducement'],
      score: 1,
      additionalInfo: 'No insurer, agent, broker, solicitor, or person, in connection with an insurance transaction shall offer or promise to buy, sell, give, or allow the prospective insured employment, stock or special favors. This is the definition of inducement.',
    },
    {
      id: '6e3d88b3-073f-4503-805b-5b7131881bd1',
      question: 'All issuers issuing long-term care insurance policies must offer, as an optional benefit a(n) ______ rider.',
      choices: [
        'Accidental',
        'Inflation',
        'Disability',
        'Deflation'
      ],
      type: 'MCQs',
      correctAnswers: ['Inflation'],
      score: 1,
      additionalInfo: 'All issuers issuing long-term care insurance policies must offer, as an optional benefit, an inflation rider.',
    },
    {
      id: '435b7f9f-7f3c-40ce-95b8-24a334d9821f',
      question: 'In regards to a life insurance contract, which of the following statements is NOT true regarding the concept of insurable interest?',
      choices: [
        'Insurable interest is established by a court of law',
        'Insurable interest must exist at the time of the application',
        'Individuals are assumed to have insurable interest in themselves',
        'Insurable interest can be established sufficiently by sentimental attachment alone'
      ],
      type: 'MCQs',
      correctAnswers: ['Insurable interest can be established sufficiently by sentimental attachment alone'],
      score: 1,
      additionalInfo: 'All of these statements on insurable interest in a life insurance contract are true EXCEPT "Insurable interest can be established sufficiently by sentimental attachment alone".',
    },
    {
      id: '9d57779e-5bac-44a1-b443-6e20d71baf44',
      question: 'Who is responsible for assembling the policy forms for insureds?',
      choices: [
        'NAIC',
        'Insurance producers',
        'Insurance carriers',
        'State Insurance Departments'
      ],
      type: 'MCQs',
      correctAnswers: ['Insurance carriers'],
      score: 1,
      additionalInfo: 'The correct answer is "Insurance carriers". The insurance carrier has become responsible for assembling the policy forms for the insured person(s).',
    },
    {
      id: '257c4609-ad4b-40be-9525-cce3ea543a99',
      question: 'Ambiguities in an insurance policy are always resolved in favor of the',
      choices: [
        'insurer',
        'insured',
        'underwriter',
        'producer'
      ],
      type: 'MCQs',
      correctAnswers: ['insured'],
      score: 1,
      additionalInfo: 'Ambiguities in an insurance policy are always resolved in favor of the insured.',
    },
    {
      id: '995b20d6-5460-46e4-84e8-add657753625',
      question: 'When an insurance appointment with a producer is terminated, the party that must notify the producer of the termination is the',
      choices: [
        'local agency',
        'Commissioner',
        'National Association of Insurance Commissioners (NAIC)',
        'insurer'
      ],
      type: 'MCQs',
      correctAnswers: ['insurer'],
      score: 1,
      additionalInfo: 'When an insurance appointment with a producer is terminated, the party that must notify the producer of the termination is the insurer.',
    },
    {
      id: '6dd19a41-3d17-4704-b415-9a7bee7fe9a6',
      question: 'Which of the following types of deductibles would apply a single deductible to both medical and dental insurance coverage?',
      choices: [
        'Combined deductible',
        'Blended deductible',
        'Integrated deductible',
        'Standard deductible'
      ],
      type: 'MCQs',
      correctAnswers: ['Integrated deductible'],
      score: 1,
      additionalInfo: 'A single deductible applied to both medical and dental insurance coverage is referred to as an integrated deductible.',
    },
    {
      id: '0a0a546a-4600-4a15-880e-fdd4f883cf7e',
      question: 'Which settlement option involves having the proceeds remain with the insurer and earnings paid on a monthly basis to the beneficiary?',
      choices: [
        'fixed period',
        'dividends only',
        'interest only',
        'extended interest'
      ],
      type: 'MCQs',
      correctAnswers: ['interest only'],
      score: 1,
      additionalInfo: 'The settlement option that allows proceeds to remain with the insurer and earnings to be paid to the beneficiary on a monthly basis is called interest only.',
    },
    {
      id: 'e6a2af87-e743-4aa3-ac79-d979a3d05541',
      question: 'Long-term care insurance',
      choices: [
        'can be cancelled solely on the basis of the insured\'s deteriorating health',
        'often is called blanket health insurance',
        'is designed to provide coverage for at least 12 consecutive months',
        'is another name for basic hospital expense insurance'
      ],
      type: 'MCQs',
      correctAnswers: ['is designed to provide coverage for at least 12 consecutive months'],
      score: 1,
      additionalInfo: 'Long-term care insurance is designed to provide coverage for at least 12 consecutive months.',
    },
    {
      id: '5e90301e-97c2-4f9a-a692-901c05a7c392',
      question: 'What happens to interest earned if the annuitant dies before the payout start date?',
      choices: [
        'It is not taxable',
        'It is taxable only if no beneficiary is named',
        'It is only taxable if contract has been in force under one year',
        'It is taxable'
      ],
      type: 'MCQs',
      correctAnswers: ['It is taxable'],
      score: 1,
      additionalInfo: 'If the annuitant dies before the payout start date, the interest earned is taxable.',
    },
    {
      id: 'bbf0b855-79f8-4607-b9e3-b631a83290fb',
      question: 'A life insurance policy written on one contract for two people in which it is payable upon the first death is called',
      choices: [
        'Split',
        'Shared',
        'Survivorship',
        'Joint'
      ],
      type: 'MCQs',
      correctAnswers: ['Joint'],
      score: 1,
      additionalInfo: 'A joint life insurance policy covers the lives of two people and is payable upon the first death.',
    },
    {
      id: 'b0b407b3-06e7-469b-bc29-c34e35ee15c2',
      question: 'Which type of life insurance is normally associated with a Payor Benefit rider?',
      choices: [
        'Spouse insurance',
        'Term rider',
        'Family income insurance',
        'Juvenile insurance'
      ],
      type: 'MCQs',
      correctAnswers: ['Juvenile insurance'],
      score: 1,
      additionalInfo: 'A Payor Benefit rider waives the premium ONLY in connection with juvenile insurance.',
    },
    {
      id: '54f85c2d-d44f-4f73-b3d6-67f57cd4738b',
      question: 'A common exclusion with Vision plans is',
      choices: [
        'eyeglass frames',
        'Lasik surgery',
        'contact lenses',
        'the examination'
      ],
      type: 'MCQs',
      correctAnswers: ['Lasik surgery'],
      score: 1,
      additionalInfo: 'Lasik surgery is a common exclusion with Vision plans.',
    },
    {
      id: '15cb412d-7b63-4f95-9a08-dfd0310890a8',
      question: 'Insurance companies determine risk exposure by which of the following?',
      choices: [
        'Population table data',
        'Law of large numbers and risk pooling',
        'Insurance exchanges',
        'Insurable interest'
      ],
      type: 'MCQs',
      correctAnswers: ['Law of large numbers and risk pooling'],
      score: 1,
      additionalInfo: 'All forms of insurance determine exposure through risk pooling and the law of large numbers.',
    },
    {
      id: 'df272350-70e1-4eb0-b5dc-3f14b0de45d2',
      question: 'Insurance companies determine risk exposure by which of the following?',
      choices: [
        'Insurance exchanges',
        'Insurable interest',
        'Population table data',
        'Law of large numbers and risk pooling'
      ],
      type: 'MCQs',
      correctAnswers: ['Law of large numbers and risk pooling'],
      score: 1,
      additionalInfo: 'All forms of insurance determine exposure through risk pooling and the law of large numbers.',
    },
    {
      id: '08bb0fa9-b568-45e1-861d-b34cdbb753a6',
      question: 'Which contract element is insurable interest a component of?',
      choices: [
        'Offer and acceptance',
        'Legal purpose',
        'Competent parties',
        'Consideration'
      ],
      type: 'MCQs',
      correctAnswers: ['Legal purpose'],
      score: 1,
      additionalInfo: 'Insurable interest is a component of legal purpose.',
    },
    {
      id: 'b26407e7-a660-490f-ad3e-d73bbbb7fb5a',
      question: 'When a decreasing term policy is purchased, it contains a decreasing death benefit and',
      choices: [
        'increasing premiums',
        'decreasing premiums',
        'level premiums',
        'variable premiums'
      ],
      type: 'MCQs',
      correctAnswers: ['level premiums'],
      score: 1,
      additionalInfo: 'A decreasing term policy is issued with a decreasing death benefit and level premiums.',
    },
    {
      id: '519e0e7a-8517-4149-ab58-4ea3981e2c20',
      question: 'According to Washington Statute, commissions may be paid to',
      choices: [
        'A salaried full-time employee who does not solicit insurance contracts',
        'Licensed producers not yet appointed to any insurer',
        'Licensed and duly appointed producers',
        'Non-licensed individuals who earn less than $5,000 annually'
      ],
      type: 'MCQs',
      correctAnswers: ['Licensed and duly appointed producers'],
      score: 1,
      additionalInfo: 'According to Washington Statute, commissions may be paid to licensed and duly appointed producers.',
    },
    {
      id: 'bbac9029-00ef-4e96-bcb6-81f550cd794b',
      question: 'Which of these is NOT considered to be a common life insurance nonforfeiture option?',
      choices: [
        'Life income annuity',
        'Extended term insurance',
        'Cash surrender',
        'Reduced paid-up insurance'
      ],
      type: 'MCQs',
      correctAnswers: ['Life income annuity'],
      score: 1,
      additionalInfo: 'All of these are common life insurance nonforfeiture options EXCEPT a life income annuity.',
    },
    {
      id: 'd38a9ab7-fdcb-4905-8640-17037734d47b',
      question: 'Which settlement option pays a stated amount to an annuitant, but no residual value to a beneficiary?',
      choices: [
        'Life income',
        'Fixed period',
        'Interest only',
        'Installment refund'
      ],
      type: 'MCQs',
      correctAnswers: ['Life income'],
      score: 1,
      additionalInfo: 'The settlement option that pays a specified amount to an annuitant, but pays no residual value to a beneficiary is known as life income.',
    },
    {
      id: 'b825d96e-65a7-4949-9a4e-7d95863a8f59',
      question: 'Which of the following annuity payout options makes no additional payments regardless of when the annuitant dies?',
      choices: [
        'Cash refund',
        'Life with period certain',
        'Life only',
        'Installment refund'
      ],
      type: 'MCQs',
      correctAnswers: ['Life only'],
      score: 1,
      additionalInfo: 'The correct answer is "Life only". The life only annuity payout option has no additional payouts regardless of when the annuitant dies.',
    },
    {
      id: '89a3a028-13aa-49ef-a66c-86fb8681f18c',
      question: 'A permanent life insurance policy where the policyowner pays premiums for a specified number of years is called a(n)',
      choices: [
        'level term policy',
        'limited pay policy',
        'adjustable policy',
        'variable universal policy'
      ],
      type: 'MCQs',
      correctAnswers: ['limited pay policy'],
      score: 1,
      additionalInfo: 'A permanent life insurance policy where the policyowner pays premiums for a specified number of years is called a limited pay policy.',
    },
    {
      id: '4d99de2b-d5b9-4b82-b6b0-873de60e5d29',
      question: 'Someone needing custodial care at home would require which type of coverage?',
      choices: [
        'Long-term care',
        'Major medical',
        'Disability',
        'Medicaid'
      ],
      type: 'MCQs',
      correctAnswers: ['Long-term care'],
      score: 1,
      additionalInfo: 'Long-term care coverage would be most appropriate for an individual who needs custodial care at home.',
    },
    {
      id: 'f6e637ef-e98c-4f28-8eea-ba18b84c87f0',
      question: 'Coming from an insurance point of view, which of the following is the main risk associated with disability?',
      choices: [
        'Loss of limbs',
        'Decreased work skills',
        'Loss of income',
        'Decreased mobility'
      ],
      type: 'MCQs',
      correctAnswers: ['Loss of income'],
      score: 1,
      additionalInfo: 'From an insurance perspective, loss of income is the primary risk associated with disability.',
    },
    {
      id: 'cd492fa6-b086-4964-8652-bb270863ccea',
      question: 'The premium for a Modified whole life policy is',
      choices: [
        'normally graded over a period of 20 years',
        'higher than the typical whole life policy during the first few years and then lower than typical for the remainder',
        'level for the first 5 years then decreases for the remainder of the policy',
        'lower than the typical whole life policy during the first few years and then higher than typical for the remainder'
      ],
      type: 'MCQs',
      correctAnswers: ['lower than the typical whole life policy during the first few years and then higher than typical for the remainder'],
      score: 1,
      additionalInfo: 'Modified whole life policies are distinguished by premiums that are lower than typical whole life premiums during the first few years and then higher than typical thereafter.',
    },
    {
      id: 'cc4cb34e-06f4-4fbe-9f4b-c18983df9343',
      question: 'Which of the following typically does NOT provide a form of managed care?',
      choices: [
        'Point-of-Service (POS) plan',
        'Preferred Provider Organization (PPO)',
        'Major medical indemnity plan',
        'Health Maintenance Organization (HMO)'
      ],
      type: 'MCQs',
      correctAnswers: ['Major medical indemnity plan'],
      score: 1,
      additionalInfo: 'Managed care is typically provided under all of these EXCEPT a "major medical indemnity plan".',
    },
    {
      id: '6450d94d-af07-4a39-a715-57801e99e004',
      question: 'What is involved when a life insurance policy has been backdated?',
      choices: [
        'Making the policy effective on an earlier date than the present',
        'Setting a policy\'s effective date prior to a preexisting condition',
        'Reinstating a lapsed policy',
        'Redating a policy after it has been issued'
      ],
      type: 'MCQs',
      correctAnswers: ['Making the policy effective on an earlier date than the present'],
      score: 1,
      additionalInfo: 'Backdating on a life insurance policy is the practice of making the policy effective on an earlier date than the present.',
    },
    {
      id: 'f979b8c6-ba2a-4a6b-b843-58d95c833a76',
      question: 'Which of the following is considered to be a point of service (POS) plan?',
      choices: [
        'Restricted provider organization',
        'Protected care provider',
        'Preferred provider organization',
        'Managed care plan'
      ],
      type: 'MCQs',
      correctAnswers: ['Managed care plan'],
      score: 1,
      additionalInfo: 'A point of service (POS) plan is an example of a managed care plan.',
    },
    {
      id: 'd9cc775a-9068-449d-8c8d-bc8f3617e88d',
      question: 'An employer is issued a group medical insurance policy. This single contract is known as a(n)',
      choices: [
        'employer contract',
        'certificate of coverage',
        'entire contract',
        'master policy'
      ],
      type: 'MCQs',
      correctAnswers: ['master policy'],
      score: 1,
      additionalInfo: 'A single contract for group medical insurance issued to an employer is known as a master policy.',
    },
    {
      id: '9aad9e88-586e-4c52-b9bc-963db9774f8f',
      question: 'What is the contract called that is issued to an employer for a Group Medical Insurance plan?',
      choices: [
        'Provisional policy',
        'Master policy',
        'Document of coverage',
        'Certificate of coverage'
      ],
      type: 'MCQs',
      correctAnswers: ['Master policy'],
      score: 1,
      additionalInfo: 'A single contract for Group Medical Insurance issued to an employer is known as a master policy.',
    },
    {
      id: 'd896d50e-1375-4966-bd1e-aae0da6340fb',
      question: 'Tyler purchased a disability policy with a waiver of premium rider on April 1. He is disabled on May 1. On June 1, he receives proof of permanent and total disability, and submits a claim. He begins receiving benefits on June 15. When are his premiums waived?',
      choices: [
        'May 1',
        'June 15',
        'April 1',
        'June 1'
      ],
      type: 'MCQs',
      correctAnswers: ['May 1'],
      score: 1,
      additionalInfo: 'Premiums are waived beginning at the date of disability.',
    },
    {
      id: 'ae32750b-abaa-478b-bb40-fd19cd25ab2f',
      question: 'Sonya applied for a health insurance policy on April 1. Her agent submitted the information to the insurance company on April 6. She paid the premium on May 15 with the policy indicating the effective date being May 30. On which date would Sonya have coverage?',
      choices: [
        'April 6',
        'April 1',
        'May 15',
        'May 30'
      ],
      type: 'MCQs',
      correctAnswers: ['May 30'],
      score: 1,
      additionalInfo: 'The "effective date" is the health insurance coverage start date.',
    },
    {
      id: '1586d865-add2-4e31-b114-3040753f5f4d',
      question: 'In contrast to a guaranteed renewable policy, a noncancellable policy',
      choices: [
        'may never raise premiums',
        'may raise premiums at policy renewal',
        'may cancel the policy only at renewal',
        'may raise premiums at anytime'
      ],
      type: 'MCQs',
      correctAnswers: ['may never raise premiums'],
      score: 1,
      additionalInfo: 'Noncancellable policies may not be changed in anyway by the insurer up to a specified age so long as the premiums are paid.',
    },
    {
      id: '57cbb786-df41-463b-949e-48a9407c193f',
      question: 'Which of these gaps in Medicare coverage is addressed with Medicare Supplemental Insurance?',
      choices: [
        'Dental work',
        'Nutritional supplements',
        'Medicare in-hospital deductible',
        'Chiropractic care'
      ],
      type: 'MCQs',
      correctAnswers: ['Medicare in-hospital deductible'],
      score: 1,
      additionalInfo: 'The purpose of Medicare Supplement Insurance is to address gaps in Medicare coverage, such as Medicare in-hospital deductibles.',
    },
    {
      id: '4f50991a-2c6a-46cd-a027-d00be235a402',
      question: 'Small employers who are sponsored by an insurer to provide group benefits to its employees are called',
      choices: [
        'MEWA',
        'Lloyd of London',
        'Fraternal Benefit Society',
        'Surplus lines brokers'
      ],
      type: 'MCQs',
      correctAnswers: ['MEWA'],
      score: 1,
      additionalInfo: 'A Multiple Employer Welfare Association consists of small employers who have joined to provide health benefits for their employees.',
    },
    {
      id: '6441295a-4a08-4821-b70d-854f29566651',
      question: 'The suicide clause of a life insurance policy states that if an insured commits suicide within a stated period from the policy\'s inception, the insurer will only be liable for a return of premiums paid',
      choices: [
        'during the last 12 months',
        'during the last 6 months',
        'minus indebtedness and without interest',
        'minus indebtedness and with interest'
      ],
      type: 'MCQs',
      correctAnswers: ['minus indebtedness and without interest'],
      score: 1,
      additionalInfo: 'The suicide clause of a life insurance policy states that if an insured commits suicide within a stated period from the policy\'s inception, the insurer will only be liable for a return of premiums paid minus indebtedness and without interest.',
    },
    {
      id: '4d77ce28-785c-4299-994b-ba3b3e944d5a',
      question: 'Which of the following would be considered an Unfair Claim Practice?',
      choices: [
        'Denying a claim after reasonable investigation of the facts',
        'Implementing standards for proper investigation',
        'Acting promptly on claims communication',
        'Misrepresenting insurance policy provisions affecting a loss'
      ],
      type: 'MCQs',
      correctAnswers: ['Misrepresenting insurance policy provisions affecting a loss'],
      score: 1,
      additionalInfo: 'Making a misrepresentation to an insured concerning the policy provisions affecting a loss is considered an unfair claim practice.',
    },
    {
      id: '28af3b93-788b-4286-bf74-c8ad42e6a665',
      question: 'Joanne has a $100,000 whole life policy with an accumulated $25,000 of cash value. She would like to borrow $15,000 against the cash value. Which of the following statements is TRUE?',
      choices: [
        'No interest will be charged on loan balance',
        'Net death benefit will be reduced if the loan is not repaid',
        'Term life policies are the only type of insurance that allows policy loans',
        'A loan can be taken out for up to the face amount of the policy'
      ],
      type: 'MCQs',
      correctAnswers: ['Net death benefit will be reduced if the loan is not repaid'],
      score: 1,
      additionalInfo: 'If the loan is not repaid, the net death benefit will be reduced by the outstanding loan balance.',
    },
    {
      id: '755fa750-5ca9-4a52-8278-1abb253621d9',
      question: 'What is the tax liability for employer contributions in Health Savings Accounts (HSA\'s)?',
      choices: [
        'Tax-deductible expense to the employee',
        'Taxable as regular income to the employee',
        'Taxable to the employee when they exceed 7 1/2 % AGI',
        'No tax payment needed'
      ],
      type: 'MCQs',
      correctAnswers: ['No tax payment needed'],
      score: 1,
      additionalInfo: 'The correct answer is "No tax payment needed". Employer-paid contributions to Health Savings Accounts are tax-free to the employee.',
    },
    {
      id: 'f673f2db-07e1-41ce-bcd0-b7d18b3c72f5',
      question: 'A producer who is licensed in Washington but resident in another state is called a',
      choices: [
        'reciprocal producer',
        'nonresident producer',
        'limited lines producer',
        'foreign producer'
      ],
      type: 'MCQs',
      correctAnswers: ['nonresident producer'],
      score: 1,
      additionalInfo: 'A producer licensed in Washington but a resident in another state is called a nonresident producer.',
    },
    {
      id: 'f818bd10-eab2-45dd-9e2a-77bb47edf124',
      question: 'A person who is not a legal resident of Washington may be licensed to act in this state without taking a written examination. What kind of license would this be?',
      choices: [
        'Nonresident',
        'Resident',
        'Foreign',
        'Admitted'
      ],
      type: 'MCQs',
      correctAnswers: ['Nonresident'],
      score: 1,
      additionalInfo: 'A person who is not a legal resident of Washington may be licensed to act as nonresident insurance producer in this state without taking a written examination.',
    },
    {
      id: '5621a900-0d32-40d3-86cf-70e4ea4852c6',
      question: 'A nonparticipating policy will',
      choices: [
        'provide tax advantages',
        'provide a return of premium',
        'not pay dividends',
        'give policyowners special privileges'
      ],
      type: 'MCQs',
      correctAnswers: ['not pay dividends'],
      score: 1,
      additionalInfo: 'When an insurer offers a policy that is nonparticipating, the insurer\'s policy does not pay dividends.',
    },
    {
      id: '3b96e55e-20a8-4458-aaff-f803196e8a6a',
      question: 'How are contributions made to a Roth IRA handled for tax purposes?',
      choices: [
        'Not tax deductible',
        'Conditionally tax deductible',
        'Fully tax deductible',
        'Partially tax deductible'
      ],
      type: 'MCQs',
      correctAnswers: ['Not tax deductible'],
      score: 1,
      additionalInfo: 'Contributions made to a Roth IRA are not tax deductible.',
    },
    {
      id: 'eaa44ac6-5538-4961-a3ab-d83e83460860',
      question: 'When replacing a life insurance policy, a producer must provide the applicant with a',
      choices: [
        'Notice Regarding Reduction',
        'Notice Regarding Reinstatement',
        'Notice Regarding Retention',
        'Notice Regarding Replacement'
      ],
      type: 'MCQs',
      correctAnswers: ['Notice Regarding Replacement'],
      score: 1,
      additionalInfo: 'When replacing a life insurance policy, a producer must provide the applicant with a Notice Regarding Replacement.',
    },
    {
      id: 'ad24b3c8-5ebb-4c3e-927c-03f1b90c3144',
      question: 'Annuity suitability information specifically includes all of the following EXCEPT',
      choices: [
        'financial experience',
        'annual income',
        'age',
        'number of dependents'
      ],
      type: 'MCQs',
      correctAnswers: ['number of dependents'],
      score: 1,
      additionalInfo: 'All of these are factored when determining annuity suitability EXCEPT "number of dependents".',
    },
    {
      id: '7e0bc6f9-72e0-4695-9d20-0964bbbdd049',
      question: 'An individual insurance producer who allows his or her license to expire may, within ___ months from the expiration date, reinstate the same license without passing a written exam.',
      choices: [
        '18',
        '24',
        '12',
        '6'
      ],
      type: 'MCQs',
      correctAnswers: ['12'],
      score: 1,
      additionalInfo: 'An individual insurance producer who allows his or her license to lapse may, within 12 months after the expiration date, reinstate the same license without the necessity of passing a written examination.',
    },
    {
      id: '743d0dc9-b219-4b9d-8f86-d8041aa76128',
      question: 'When a producer is replacing an existing ordinary life insurance policy, the producer must take all of the following actions EXCEPT',
      choices: [
        'give the applicant a Notice Regarding Replacement of Life Insurance',
        'give the applicant the original or a copy of the proposals used in the sales presentation',
        'obtain the beneficiary\'s signature on a summary of all policies to be replaced',
        'submit to the producer\'s insurance company a copy of the Notice Regarding Replacement of Life Insurance signed by the applicant'
      ],
      type: 'MCQs',
      correctAnswers: ['obtain the beneficiary\'s signature on a summary of all policies to be replaced'],
      score: 1,
      additionalInfo: 'All of these actions are required when a producer is replacing an existing ordinary life insurance policy EXCEPT obtaining the beneficiary\'s signature.',
    },
    {
      id: '2626dbea-4830-447f-91a0-e1e3339d2ab9',
      question: 'Suitability is',
      choices: [
        'delivering the Buyer\'s Guide and Policy Summary to the applicant',
        'properly applying the insurer\'s underwriting guidelines to an applicant',
        'obtaining information from the applicant to determine whether an insurance or annuity product is appropriate',
        'converting a term policy to a whole life policy'
      ],
      type: 'MCQs',
      correctAnswers: ['obtaining information from the applicant to determine whether an insurance or annuity product is appropriate'],
      score: 1,
      additionalInfo: 'Suitability is obtaining information from the applicant to determine whether an insurance or annuity product is appropriate.',
    },
    {
      id: '43e83472-9e14-44c4-b09a-b2bf21655ebd',
      question: 'How do interest earnings accumulate in a deferred annuity?',
      choices: [
        'On a taxable basis',
        'On a tax credit basis',
        'On a tax-deferred basis',
        'On a tax-free basis'
      ],
      type: 'MCQs',
      correctAnswers: ['On a tax-deferred basis'],
      score: 1,
      additionalInfo: 'Deferred annuities accumulate interest earnings on a tax-deferred basis.',
    },
    {
      id: '175d60cb-a787-43f8-a01a-0280a90fee4f',
      question: 'A clause that allows an insurer the right to terminate coverage at any anniversary date is called a(n)',
      choices: [
        'selective renewability clause',
        'cancelable clause',
        'optional renewability clause',
        'conditionally renewability clause'
      ],
      type: 'MCQs',
      correctAnswers: ['optional renewability clause'],
      score: 1,
      additionalInfo: 'An optional renewability clause allows an insurer the unrestricted right to terminate coverage at any anniversary or at any premium due date.',
    },
    {
      id: '53c856ee-7f15-4bd1-bd53-72373e864d10',
      question: 'Richard owns an insurance policy that is renewable only at the option of the insurance company. His policy is considered to be',
      choices: [
        'cancelable',
        'guaranteed renewable',
        'conditionally renewable',
        'optionally renewable'
      ],
      type: 'MCQs',
      correctAnswers: ['optionally renewable'],
      score: 1,
      additionalInfo: 'A policy of insurance that is renewable only at the option of the insurer is optionally renewable.',
    },
    {
      id: 'dbd8a3b7-9ed0-4688-ab02-302dd2e31b69',
      question: 'Which clause defines total disability as being unable to perform the major duties of the insured\'s regular occupation?',
      choices: [
        'Own occupation clause',
        'Presumptive clause',
        'Any occupation clause',
        'Residual clause'
      ],
      type: 'MCQs',
      correctAnswers: ['Own occupation clause'],
      score: 1,
      additionalInfo: 'The own occupation clause states that insureds are totally disabled when they cannot perform the major duties of their regular occupation.',
    },
    {
      id: '92acb06a-a7bb-41a1-adc1-c360412d312b',
      question: 'A provision that allows a policyowner to withdraw a policy\'s cash value interest free is a(n)',
      choices: [
        'grace period',
        'partial surrender',
        'waiver of premium',
        'automatic premium loan'
      ],
      type: 'MCQs',
      correctAnswers: ['partial surrender'],
      score: 1,
      additionalInfo: 'A partial surrender allows the policyowner to withdraw the policy\'s cash value interest free.',
    },
    {
      id: '0379b837-e9fe-4a58-a02c-e1ff32dc6aab',
      question: 'According to the Health Insurance Portability and Accountability Act (HIPAA), when can a group health policy renewal be denied?',
      choices: [
        'There have been too many claims in the previous year',
        'The size of the group has increased by more than 10%',
        'Participation or contribution rules have been changed',
        'Participation or contribution rules have been violated'
      ],
      type: 'MCQs',
      correctAnswers: ['Participation or contribution rules have been violated'],
      score: 1,
      additionalInfo: 'According to the Health Insurance Portability and Accountability Act (HIPAA), a group health policy renewal can be denied when participation or contribution rules have been violated.',
    },
    {
      id: 'd316bd94-fc39-43ea-ae17-55cd0cdd1c19',
      question: 'What is an insurer required to do when faced with an error made under the Misstatement of Age provision?',
      choices: [
        'Cancel the policy',
        'Pay full benefits as stated in the policy',
        'Pay age-corrected benefits',
        'Bill the policyowner for back premiums'
      ],
      type: 'MCQs',
      correctAnswers: ['Pay age-corrected benefits'],
      score: 1,
      additionalInfo: 'Under the Misstatement of Age Provision, when done in error, an insurer must pay age-corrected benefits.',
    },
    {
      id: '12661940-3f3c-4b06-a30b-a6f1840b7161',
      question: 'The cause of a loss is referred to as a(n)',
      choices: [
        'hazard',
        'adversity',
        'risk',
        'peril'
      ],
      type: 'MCQs',
      correctAnswers: ['peril'],
      score: 1,
      additionalInfo: 'A peril is an event or circumstance that causes or may potentially cause a loss. Examples of perils include fire, flooding, hailstorms, tornado, hurricane, auto accident or home accident such as falling.',
    },
    {
      id: '725c23a5-1518-4452-b43b-3904443e61f5',
      question: 'Which annuity payout option allows the policyowner to choose a predetermined number of benefit payments?',
      choices: [
        'Straight life',
        'Installment refund',
        'Amount certain',
        'Period certain'
      ],
      type: 'MCQs',
      correctAnswers: ['Period certain'],
      score: 1,
      additionalInfo: 'An annuity where the policyowner chooses a pre-determined number of benefit pavments is called period certain.',
    },
    {
      id: '4cf3c3ee-4fe6-4b34-bafe-de38ac3c919d',
      question: 'What is an insurance policy\'s grace period?',
      choices: [
        'Period of time after the initial premium is paid and before the policy is issued',
        'Period of time after the premium is due but the policy remains in force',
        'Period of time after a policy is issued and before it is delivered to policyowner',
        'Period of time it takes for a policy\'s underwriting to complete'
      ],
      type: 'MCQs',
      correctAnswers: ['Period of time after the premium is due but the policy remains in force'],
      score: 1,
      additionalInfo: 'The grace period is a period of time after the premium is due but the policy remains in force.',
    },
    {
      id: '8e6b85da-3698-4041-a36a-580ab191bd54',
      question: 'Which type of disability would be less than total impairment and equal to permanent impairment?',
      choices: [
        'Permanent partial disability',
        'Temporary partial disability',
        'Partial total disability',
        'Residual partial disability'
      ],
      type: 'MCQs',
      correctAnswers: ['Permanent partial disability'],
      score: 1,
      additionalInfo: 'Permanent disability that is less than total impairment and equal to permanent impairment is the definition of permanent partial disability.',
    },
    {
      id: 'f4fecf42-6f63-4dd0-8857-64b8fa01bded',
      question: 'Which of these factors is NOT taken into consideration when determining the cost of a long-term care policy?',
      choices: [
        'Age of applicant',
        'Personal income',
        'Health of applicant',
        'Amount of benefits provided'
      ],
      type: 'MCQs',
      correctAnswers: ['Personal income'],
      score: 1,
      additionalInfo: 'The cost of a long-term care policy is based on all of these factors EXCEPT personal income.',
    },
    {
      id: '54926711-42a3-479f-b2e1-7b318b12a865',
      question: 'Which of the following is NOT a common exclusion for a medical expense policy?',
      choices: [
        'Self inflicted injury',
        'On-the-job injury',
        'Act of war',
        'Physical therapy'
      ],
      type: 'MCQs',
      correctAnswers: ['Physical therapy'],
      score: 1,
      additionalInfo: 'Physical therapy is not considered to be a common exclusion for a medical expense policy.',
    },
    {
      id: 'a48ea63d-f17a-44d3-9823-b03c27341149',
      question: 'Which of the following would evidence ownership in a participating health insurance contract?',
      choices: [
        'Policy ownership',
        'Collateral assignment',
        'Stock ownership',
        'Irrevocable beneficiary status'
      ],
      type: 'MCQs',
      correctAnswers: ['Policy ownership'],
      score: 1,
      additionalInfo: 'Policy ownership would evidence ownership in a participating health insurance contract.',
    },
    {
      id: 'c128932f-ca0c-4446-91e4-68e8857cf3a8',
      question: 'Which of these is considered to be a document that describes the critical segments of a life insurance policy?',
      choices: [
        'Policy summary',
        'Buyer\'s summary',
        'Buyer\'s guide',
        'Consumer report'
      ],
      type: 'MCQs',
      correctAnswers: ['Policy summary'],
      score: 1,
      additionalInfo: 'A document that specifies the critical segments of an insured\'s life insurance policy is known as a policy summary.',
    },
    {
      id: '0672aa04-1676-4849-b935-2f6dffc11a66',
      question: 'Variable life insurance and Universal life insurance are very similar. Which of these features are held exclusively by variable universal life insurance?',
      choices: [
        'Policyowner may increase or decrease the premium payments',
        'Policyowner can contribute large sums of money',
        'policyowner has the right to select the investment which wil provide the greatest return',
        'Policyowner may increase or decrease the face amount'
      ],
      type: 'MCQs',
      correctAnswers: ['policyowner has the right to select the investment which wil provide the greatest return'],
      score: 1,
      additionalInfo: 'The right to select the investment which will provide the greatest return pertains only to variable universal life insurance.',
    },
    {
      id: '495ba745-d602-4167-b68b-8bee341ba988',
      question: 'During the accumulation period, who can surrender an annuity?',
      choices: [
        'Policyowner',
        'Payor',
        'Beneficiary',
        'Annuitant'
      ],
      type: 'MCQs',
      correctAnswers: ['Policyowner'],
      score: 1,
      additionalInfo: 'The policyowner is the only one who can surrender an annuity during the accumulation period.',
    },
    {
      id: 'ee3bd40f-3340-4300-8499-ff77b4319e92',
      question: 'Medicaid is a government-funded program designed to provide health care to',
      choices: [
        'all individuals who carry Medicare supplemental insurance',
        'anyone who does not have a proper caregiver',
        'all individuals over the age of 65',
        'poor people'
      ],
      type: 'MCQs',
      correctAnswers: ['poor people'],
      score: 1,
      additionalInfo: 'Medicaid is a government funded program designed to provide health care to poor people.',
    },
    {
      id: 'b82180d2-4c35-4b0f-92d0-2045f8efd498',
      question: 'Which of these describes the result of a modified endowment contract that failed to meet the seven-pay test?',
      choices: [
        'Withdrawals will be prohibited',
        'Pre-death distributions are typically taxable',
        'The premium payments will be tax deductible',
        'Policy loans are disallowed'
      ],
      type: 'MCQs',
      correctAnswers: ['Pre-death distributions are typically taxable'],
      score: 1,
      additionalInfo: 'Failing to meet the seven-pay test results in pre-death distributions likely to become taxable.',
    },
    {
      id: '518718c6-3588-4de4-9930-d80757b93463',
      question: 'Coordination of Benefits regulation applies to all of the following plans EXCEPT',
      choices: [
        'Group health plan',
        'Preferred Provider Organization plan',
        'Group vision plan',
        'Self-funded group health plan'
      ],
      type: 'MCQs',
      correctAnswers: ['Preferred Provider Organization plan'],
      score: 1,
      additionalInfo: 'Coordination of Benefits regulation on group health policies does not apply to individual health insurance policies.',
    },
    {
      id: '163e5718-1149-4129-a1ed-6b951e027f46',
      question: 'A person who is a nonsmoker, of average weight, and in excellent health would most likely be in which risk classification?',
      choices: [
        'Standard',
        'Acceptable',
        'Substandard',
        'Preferred'
      ],
      type: 'MCQs',
      correctAnswers: ['Preferred'],
      score: 1,
      additionalInfo: 'In this situation, the individual will most likely have a preferred risk classification.',
    },
    {
      id: '33b18b47-b48c-4636-9324-eababe4e23aa',
      question: 'Which of the following does Medicare Part D cover?',
      choices: [
        'Prescription drugs',
        'Hospital coverage',
        'Intensive care coverage',
        'Doctor visits'
      ],
      type: 'MCQs',
      correctAnswers: ['Prescription drugs'],
      score: 1,
      additionalInfo: 'Medicare Part D covers prescription drugs.',
    },
    {
      id: 'd3ac64b3-dbfa-44a4-8e27-cfad9136da53',
      question: 'In most dental plans, a deductible typically is NOT applied to',
      choices: [
        'restorative dentistry',
        'preventative and diagnostic services',
        'orthodontics',
        'oral surgery'
      ],
      type: 'MCQs',
      correctAnswers: ['preventative and diagnostic services'],
      score: 1,
      additionalInfo: 'Preventative and diagnostic services are normally fully paid in most dental plans.',
    },
    {
      id: 'c65a77e2-5111-4cc1-ac45-5a6e580ded3c',
      question: 'All of the following are functions of an insuring clause EXCEPT',
      choices: [
        'Primarily describes the free-look period',
        'Outlines the kind of benefits provided',
        'States the conditions under which the policy will pay',
        'Provides the policy\'s scope and limits of coverage'
      ],
      type: 'MCQs',
      correctAnswers: ['Primarily describes the free-look period'],
      score: 1,
      additionalInfo: 'All of the following are functions of an insuring clause EXCEPT "Primarily describes the free-look period".',
    },
    {
      id: '817bd988-35a9-477b-acb2-86e26bdb160a',
      question: 'A person required to be licensed pursuant to the laws of this state to sell, solicit or negotiate insurance is called a',
      choices: [
        'association',
        'fraternal benefit society',
        'Commissioner',
        'Producer'
      ],
      type: 'MCQs',
      correctAnswers: ['Producer'],
      score: 1,
      additionalInfo: 'A person required to be licensed pursuant to the laws of this state to sell, solicit or negotiate insurance is called a producer.',
    },
    {
      id: '09ae8b58-3508-428f-a13e-88443aae02d4',
      question: 'When must a claim on a life insurance policy be paid after proof of loss has been received by the insurer?',
      choices: [
        'Within 30 days',
        'Promptly',
        'Within 10 days',
        'Within 20 days'
      ],
      type: 'MCQs',
      correctAnswers: ['Promptly'],
      score: 1,
      additionalInfo: 'A claim on a life insurance policy must be paid promptly after proof of loss has been received by the insurer.',
    },
    {
      id: 'd6a2bd3b-b96d-4718-87af-c2d9fa9d82df',
      question: 'Which of the following is a reinstatement condition?',
      choices: [
        'Premium decrease',
        'Changes in the insuring clause',
        'Premium increase',
        'Proof of insurability'
      ],
      type: 'MCQs',
      correctAnswers: ['Proof of insurability'],
      score: 1,
      additionalInfo: 'One of the conditions required for an insurance policy reinstatement is proof of insurability.',
    },
    {
      id: 'd901f9af-277f-42b4-9296-c29f894234a8',
      question: 'When comparing an HMO to a PPO, the PPO',
      choices: [
        'always requires a referral to specialists',
        'always requires service in a network',
        'provides a greater choice of providers',
        'is a prepaid medical service plan'
      ],
      type: 'MCQs',
      correctAnswers: ['provides a greater choice of providers'],
      score: 1,
      additionalInfo: 'Service may be covered out of network, thereby allowing for greater choice of providers with a PPO.',
    },
    {
      id: '4af6717a-dd82-4f8c-b51b-a530b336ad83',
      question: 'Any inducement offered to the insured in the sale of insurance products that is not specified in the policy is called',
      choices: [
        'Misrepresentation',
        'Defamation',
        'Twisting',
        'Rebating'
      ],
      type: 'MCQs',
      correctAnswers: ['Rebating'],
      score: 1,
      additionalInfo: 'Any inducement offered to the insured in the sale of insurance products that is not specified in the policy is called rebating.',
    },
    {
      id: '50ac276e-52b0-470d-8e9a-9bd65d8da10b',
      question: 'The role of the federal government was expanded when Medicaid was established by allowing the state to',
      choices: [
        'form a large PPO on a statewide level',
        'purchase health insurance from the federal government',
        'receive matching funds to expand public assistance programs',
        'defer all costs to the federal government for public assistance programs'
      ],
      type: 'MCQs',
      correctAnswers: ['receive matching funds to expand public assistance programs'],
      score: 1,
      additionalInfo: 'Medicaid was created to expand the role of the federal government in health care financing by permitting the state to receive matching funds to expand public assistance programs.',
    },
    {
      id: '94095484-4ba7-42f6-bf92-c933f4b94278',
      question: 'Under federal law, an individual convicted of a felony involving dishonesty may engage in the business of insurance ONLY after:',
      choices: [
        'Paying a fine of at least $5,000',
        'Serving five years in prison',
        'Completing two years of community service',
        'Receiving written consent from the state insurance regulatory agency'
      ],
      type: 'MCQs',
      correctAnswers: ['Receiving written consent from the state insurance regulatory agency'],
      score: 1,
      additionalInfo: 'Under federal law, an individual convicted of a felony involving dishonesty may engage in the business of insurance ONLY after receiving written consent from the state insurance regulatory agency.',
    },
    {
      id: '4ee56960-9409-4d01-9c27-180a482058f2',
      question: 'A producer\'s fiduciary responsibility includes which of the following activities?',
      choices: [
        'Properly remunerating persons who furnish leads on prospects',
        'Providing needed insurance to prospective insureds through any sources that are available',
        'Recording the receipt and distribution of premiums due to an insurer',
        'Guaranteeing payment to insureds for losses covered by policies that the producer sold'
      ],
      type: 'MCQs',
      correctAnswers: ['Recording the receipt and distribution of premiums due to an insurer'],
      score: 1,
      additionalInfo: 'A producer shall use reasonable accounting methods to record funds received in his or her fiduciary capacity including the receipt and distribution of all premiums due each of his or her insurers.',
    },
    {
      id: '324675b3-f9ad-4c4a-9e2a-78f033c22f28',
      question: 'A policyowner may exercise which of these dividend options that uses the dividend to pay all or part of the next premium due?',
      choices: [
        'Cash dividend option',
        'Extended term option',
        'Paid-up option',
        'Reduction of premium dividend option'
      ],
      type: 'MCQs',
      correctAnswers: ['Reduction of premium dividend option'],
      score: 1,
      additionalInfo: 'The reduction of premium dividend option allows a policyowner to use the dividend to pay all or part of the next premium due on the policy.',
    },
    {
      id: '43c57217-cf77-4384-9d3c-106ba4997a17',
      question: 'An annuitant dies during the distribution period. What kind of annuity will return to a beneficiary the difference between the annuity value and the income payments already made?',
      choices: [
        'Return annuity',
        'Rebate annuity',
        'Refund annuity',
        'Variable annuity'
      ],
      type: 'MCQs',
      correctAnswers: ['Refund annuity'],
      score: 1,
      additionalInfo: 'An annuity that returns the difference between the annuity value and the income payments made to a beneficiary when the annuitant dies during the distribution period is a refund annuity.',
    },
    {
      id: '08df8ba3-fa7f-4c19-939d-7dd431125e03',
      question: 'A life insurance company has transferred some of its risk to another insurer. The insurer assuming the risk is called the',
      choices: [
        'reciprocal insurer',
        'participating insurer',
        'mutual insurer',
        'reinsurer'
      ],
      type: 'MCQs',
      correctAnswers: ['reinsurer'],
      score: 1,
      additionalInfo: 'When risk is transferred from one insurer to another, the insurer assuming the risk is called the reinsurer.',
    },
    {
      id: '5570643b-4b44-46e5-925d-4398d064f7d1',
      question: 'What is the automatic continuance of insurance coverage referred to as?',
      choices: [
        'resumption',
        'renovation',
        'renewal',
        'reinstatement'
      ],
      type: 'MCQs',
      correctAnswers: ['renewal'],
      score: 1,
      additionalInfo: 'The automatic continuance of insurance coverage is called renewal.',
    },
    {
      id: 'c342bb57-ca91-48b5-bd57-8cb13af87708',
      question: 'A transaction in which a new life insurance policy is purchased and an existing life insurance policy is surrendered is called',
      choices: [
        'Nonforfeiture',
        'Replacement',
        'Reinvestment',
        'Rollover'
      ],
      type: 'MCQs',
      correctAnswers: ['Replacement'],
      score: 1,
      additionalInfo: 'A transaction in which a new life insurance policy is purchased and an existing life insurance policy is surrendered is called a replacement.',
    },
    {
      id: '68ab126e-32b2-488b-8f97-826ca46f9b18',
      question: 'In the state of Washington, a licensed producer',
      choices: [
        'represents the insured',
        'represents the insurer',
        'may modify insurance contracts',
        'approves insurance contracts'
      ],
      type: 'MCQs',
      correctAnswers: ['represents the insurer'],
      score: 1,
      additionalInfo: 'In the state of Washington, a licensed producer represents the insurer.',
    },
    {
      id: 'be1b1def-ffd9-4a8d-807a-ed1cae680cc2',
      question: 'Under the subrogation clause, legal action can be taken by the insurer against the',
      choices: [
        'State',
        'policyowner',
        'responsible third party',
        'beneficiary'
      ],
      type: 'MCQs',
      correctAnswers: ['responsible third party'],
      score: 1,
      additionalInfo: 'Under the subrogation clause, legal action can be taken by the insurer against the responsible third party.',
    },
    {
      id: '3360527f-abda-491c-80e6-616accaf241d',
      question: 'Kurt is an active duty serviceman who was recently killed in an accident while home on leave. Which military service exclusion clause would pay upon his death?',
      choices: [
        'Results',
        'Leave',
        'Status',
        'Active'
      ],
      type: 'MCQs',
      correctAnswers: ['Results'],
      score: 1,
      additionalInfo: 'The "results clause" states the insurer is excused from paying the amount only if the death is a result of war.',
    },
    {
      id: '5a852ca1-b3bb-4952-b12e-14e454e3d007',
      question: 'An annuity is primarily used to provide',
      choices: [
        'death benefits',
        'long-term care benefits',
        'retirement income',
        'disability income'
      ],
      type: 'MCQs',
      correctAnswers: ['retirement income'],
      score: 1,
      additionalInfo: 'The principal use of an annuitv is to provide income for retirement.',
    },
    {
      id: 'f35b7bea-bddb-4299-b0ae-5b7a54059067',
      question: 'A life insurance policyowner does NOT have the right to',
      choices: [
        'take out a policy loan',
        'select a beneficiary',
        'revoke an absolute assignment',
        'change a beneficiary'
      ],
      type: 'MCQs',
      correctAnswers: ['revoke an absolute assignment'],
      score: 1,
      additionalInfo: 'A policyowner may not revoke an absolute assignment.',
    },
    {
      id: 'adbe8385-3158-48c1-ad10-10925ad53fbb',
      question: 'Term insurance is appropriate for someone who',
      choices: [
        'seeks living benefits for themselves',
        'seeks temporary protection and lower premiums',
        'seeks a policy that builds cash value',
        'seeks permanent protection and higher premiums'
      ],
      type: 'MCQs',
      correctAnswers: ['seeks temporary protection and lower premiums'],
      score: 1,
      additionalInfo: 'Term insurance is appropriate for individuals seeking temporary protection and lower premiums.',
    },
    {
      id: '54fa66fd-5b64-4173-a1e1-f3f98d63c30f',
      question: 'Which of the following are the premium payments for a Universal life policy NOT used for?',
      choices: [
        'Loading costs',
        'Death benefits',
        'Separate account investments',
        'Cash value'
      ],
      type: 'MCQs',
      correctAnswers: ['Separate account investments'],
      score: 1,
      additionalInfo: 'Premium payments for a Universal life policy are NOT used for separate account investments.',
    },
    {
      id: '5f3fae5c-ebdd-4697-816d-db905928b262',
      question: 'According to the Affordable Care Act, what metal tier is required to have an actuarial value of 70% with covered individuals paying 30% through deductibles, co-pays, and other cost sharing features?',
      choices: [
        'Silver Plan',
        'Bronze Plan',
        'Platinum Plan',
        'Gold Plan'
      ],
      type: 'MCQs',
      correctAnswers: ['Silver Plan'],
      score: 1,
      additionalInfo: 'The Silver Plan requires an actuarial value of 70% with covered individuals paying 30% through deductibles, co-pays, and other cost sharing features.',
    },
    {
      id: 'ee4dcd2c-b5ab-4632-8b5e-29902f92fbe9',
      question: 'Typically, Long-Term Disability benefits are coordinated with which benefit plan?',
      choices: [
        'Accrued sick time',
        'Individual Retirement Account',
        'Social Security',
        'Life insurance'
      ],
      type: 'MCQs',
      correctAnswers: ['Social Security'],
      score: 1,
      additionalInfo: 'In most cases, Long-Term Disability benefits are coordinated with Social Security benefits.',
    },
    {
      id: 'dea7eda8-f8fd-40c0-8830-f3d2b6f5200b',
      question: 'An insurer\'s claim settlement practices are regulated by the',
      choices: [
        'State insurance departments',
        'Securities and Exchange Commission (SEC)',
        'National Association of Claims Adjusters (NACA)',
        'National Association of Insurance Commissioners (NAIC)'
      ],
      type: 'MCQs',
      correctAnswers: ['State insurance departments'],
      score: 1,
      additionalInfo: 'The correct answer is "State insurance departments". Claim settlement practices of insurers are regulated by State insurance departments.',
    },
    {
      id: '171bd28f-1489-4322-bb75-793b992a06f1',
      question: 'The insurer has the right to examine the insured during the claim process and to an autopsy when death is involved and where it is not forbidden by ____',
      choices: [
        'State law',
        'Local law',
        'Federal law',
        'County law'
      ],
      type: 'MCQs',
      correctAnswers: ['State law'],
      score: 1,
      additionalInfo: 'The correct answer is "State law". The insurer has the right to examine the insured during the claim process and to an autopsy when death is involved and where it is not forbidden by state law.',
    },
    {
      id: 'a56f3cd0-fde6-4abb-b390-d7ca58fb89c2',
      question: 'Which of these will have the highest monthly payout upon annuitization?',
      choices: [
        'Straight life',
        'Joint life',
        'Life with period certain',
        'Joint and survivor life'
      ],
      type: 'MCQs',
      correctAnswers: ['Straight life'],
      score: 1,
      additionalInfo: 'A straight life annuity will have the highest monthly payout.',
    },
    {
      id: '78cbc5aa-4c39-4336-a3c6-468b2706bd59',
      question: 'Which type of multiple protection policy pays on the death of the last person?',
      choices: [
        'Joint life policy',
        'Survivorship life policy',
        'Multiple life policy',
        'Dual life policy'
      ],
      type: 'MCQs',
      correctAnswers: ['Survivorship life policy'],
      score: 1,
      additionalInfo: 'A survivorship life policy pays on the death of the last person.',
    },
    {
      id: 'f3909451-9854-4622-99c8-b9efafabe561',
      question: 'Jonas has disability insurance through his employer. The employer pays 75% of the premium, and Jonas pays the other 25%. What is Jonas\'s tax liability for any benefits paid from the disability plan?',
      choices: [
        'No taxes are payable on any benefits received',
        'Taxes must be paid on 75% of the benefits received',
        'Taxes must be paid on 25% of the benefits received',
        'Taxes must be paid on all benefits received'
      ],
      type: 'MCQs',
      correctAnswers: ['Taxes must be paid on 75% of the benefits received'],
      score: 1,
      additionalInfo: 'In this situation, the insured must pay taxes on 75% of the benefits received.',
    },
    {
      id: '33dfec8f-91d6-4fd3-912e-6a433c9e4f6c',
      question: 'Which of the following is typically NOT eligible for coverage in a group health policy?',
      choices: [
        'Business owner',
        'Temporary employee',
        'Partner in a partnership',
        'Full-time employee'
      ],
      type: 'MCQs',
      correctAnswers: ['Temporary employee'],
      score: 1,
      additionalInfo: 'A temporary employee is typically NOT eligible for coverage in a group health policy.',
    },
    {
      id: 'a0c7e36e-8b32-40db-bac7-062002e13d7c',
      question: 'Respite care is able to provide',
      choices: [
        'permanent relief to the patient\'s primary caregiver',
        'weekly benefit payments to supplement the primary caregiver\'s income',
        'temporary relief to the patient\'s primary caregiver',
        'health care to the patient\'s primary caregiver'
      ],
      type: 'MCQs',
      correctAnswers: ['temporary relief to the patient\'s primary caregiver'],
      score: 1,
      additionalInfo: 'The primary purpose of respite care is to provide temporary relief to the patient\'s primary caregiver.',
    },
    {
      id: '4e55eff0-f2ea-4b69-867f-97493aea99d0',
      question: 'Which of the following decisions would a Health Savings Account (HSA) owner NOT be able to make?',
      choices: [
        'The amount contributed by the owner',
        'The amount contributed by the employer',
        'The medical expenses paid for by the HSA',
        'The underlying account investments used'
      ],
      type: 'MCQs',
      correctAnswers: ['The amount contributed by the employer'],
      score: 1,
      additionalInfo: 'An individual with a Health Savings Account (HSA) can make all of these decisions EXCEPT how much the employer will contribute.',
    },
    {
      id: '84b4e609-aaf3-4d92-934d-533cb1d97717',
      question: 'Which of these statements regarding the annuitant is CORRECT?',
      choices: [
        'The contract can only be assigned by the annuitant',
        'The annuitant\'s life expectancy determines the annuity payments',
        'The annuitant must also be the beneficiary',
        'The annuitant is the only individual who can surrender the contract'
      ],
      type: 'MCQs',
      correctAnswers: ['The annuitant\'s life expectancy determines the annuity payments'],
      score: 1,
      additionalInfo: 'Annuity payments are based on the annuitant\'s life expectancy.',
    },
    {
      id: '38b8775b-d8b2-4649-9b6a-ff3e14e4b2de',
      question: 'Which of these is NOT a characteristic of the Accelerated Death Benefit option?',
      choices: [
        'The benefit can be offered as a rider at a specific extra cost or may be at no cost',
        'Before payment of the benefit is made, specific conditions must exist, such as suffering from a terminal illness',
        'The face amount and policy premium are not affected by the payment',
        'There may be a dollar limit on the maximum benefit'
      ],
      type: 'MCQs',
      correctAnswers: ['The benefit can be offered as a rider at a specific extra cost or may be at no cost'],
      score: 1,
      additionalInfo: 'The correct answer is "The benefit can be offered as a rider at a specific extra cost or may be at no cost ". Accelerated Death Benefit options are offered with NO increase in premium.',
    },
    {
      id: '21418761-ea61-4e82-b28e-3887ad72bc05',
      question: 'Bill the producer is collecting the initial premium on a health policy. Which of the following statements is true?',
      choices: [
        'The contract is not in force without the initial premium being paid',
        'A partial initial premium is acceptable',
        'A claim must be honored by the insurer even if an initial premium is not paid',
        'The insured has no advantage to pay the initial premium at the time of application'
      ],
      type: 'MCQs',
      correctAnswers: ['The contract is not in force without the initial premium being paid'],
      score: 1,
      additionalInfo: 'No contract is in force without an initial premium being paid.',
    },
    {
      id: '2dba505a-657f-4344-a0b8-c8bb1a7d392a',
      question: 'James is the insured on a life insurance policy where his age was misstated on the application. Which of the following is CORRECT regarding the death benefit amount?',
      choices: [
        'The original face amount will be paid to the beneficiary',
        'The policy will be voided with no death benefits paid',
        'The amount of premiums paid will be returned with interest',
        'The death benefit paid will be what the premium would have purchased at the correct age'
      ],
      type: 'MCQs',
      correctAnswers: ['The death benefit paid will be what the premium would have purchased at the correct age'],
      score: 1,
      additionalInfo: 'Under the Misstatement of Age provision, the amount paid will be what the premium would have purchased at the correct age.',
    },
    {
      id: '0721adf6-3fa4-41f8-9c4e-2c1ccd09c0ba',
      question: 'This MANDATORY health policy provision states that the policy, including endorsements and attached papers, constitutes',
      choices: [
        'the legal purpose of the contract',
        'the conformity of state statutes',
        'the partial insurance contract between the parties',
        'the entire insurance contract between the parties'
      ],
      type: 'MCQs',
      correctAnswers: ['the entire insurance contract between the parties'],
      score: 1,
      additionalInfo: 'The entire contract provision states that the policy, including endorsements and attached papers, constitutes the entire insurance contract between the parties.',
    },
    {
      id: '27a87359-272b-40fc-9335-41256d60f412',
      question: 'When calculating the amount of life insurance needed for an income earner, what has to be determined when using the Needs Approach?',
      choices: [
        'The income earner\'s credit score',
        'The insurance company\'s financial rating',
        'The family\'s financial objectives if the income earner were to die or become disabled',
        'The income earner\'s future projected income'
      ],
      type: 'MCQs',
      correctAnswers: ['The family\'s financial objectives if the income earner were to die or become disabled'],
      score: 1,
      additionalInfo: 'When using the needs approach to determine the amount of life insurance needed for the income earner, it is necessary to determine the family\'s financial objectives in the event of the death or disability of the income earner.',
    },
    {
      id: '25830740-cad9-4a2a-ba11-ea613667ff8f',
      question: 'A Medicare Supplement basic benefit is',
      choices: [
        'term life insurance',
        'respite care',
        'the first 3 pints of blood per year',
        'disability income'
      ],
      type: 'MCQs',
      correctAnswers: ['the first 3 pints of blood per year'],
      score: 1,
      additionalInfo: 'The first 3 pints of blood each year is a basic benefit of Medicare Supplemental insurance.',
    },
    {
      id: '20f56962-b4e6-4e81-8610-5bd242b00ab8',
      question: 'An insured individual and the policy\'s beneficiary die from the same accident. The common disaster provision states the insurer will continue as if',
      choices: [
        'no beneficiary was ever named',
        'the insured outlived the beneficiary',
        'the insured and beneficiary died at the same time',
        'the beneficiary outlived the insured'
      ],
      type: 'MCQs',
      correctAnswers: ['the insured outlived the beneficiary'],
      score: 1,
      additionalInfo: 'A common disaster provision states that if the beneficiary dies from the same accident as the insured individual, the insurer will proceed as if the insured outlived the beneficiary. This allows the proceeds to go to the contingent beneficiary.',
    },
    {
      id: '169e10c5-1ebb-43a2-9fa5-dccd5e7b0ce3',
      question: 'Which of the following is permitted in an advertisement for an insurance policy?',
      choices: [
        'The insurer\'s name',
        'That the insurer is endorsed by the State',
        'Misleading information',
        'Guaranteed benefit amounts'
      ],
      type: 'MCQs',
      correctAnswers: ['The insurer\'s name'],
      score: 1,
      additionalInfo: 'The insurer\'s name is permitted in an advertisement for an insurance policy.',
    },
    {
      id: '20564d6b-a9b5-448f-8e3b-90346749d7c1',
      question: 'Under a life insurance policy, what does the insuring clause state?',
      choices: [
        'The insurer\'s obligation to return all premiums upon an approved death claim',
        'The agent\'s obligation to pay a death benefit upon an approved death claim',
        'The insurer\'s obligation to pay a death benefit upon an approved death claim',
        'The agent\'s obligation to provide the proper amount of coverage'
      ],
      type: 'MCQs',
      correctAnswers: ['The insurer\'s obligation to pay a death benefit upon an approved death claim'],
      score: 1,
      additionalInfo: 'While a life policy is in force, the insuring clause states the insurer\'s obligation is to pay the death benefit to the beneficiary when a death claim is approved.',
    },
    {
      id: 'eda3d38c-f736-4029-abd7-81ecc1915e46',
      question: 'What is a certificate of authority?',
      choices: [
        'An insurer\'s contract with its agents',
        'The license required for an insurer to conduct business in the state',
        'The approval by the department of insurance for an insurer to use its advertising',
        'A document indicating an insurer\'s membership in the guarantee fund'
      ],
      type: 'MCQs',
      correctAnswers: ['The license required for an insurer to conduct business in the state'],
      score: 1,
      additionalInfo: 'A certificate of authority is the license required for an insurer to conduct business in the state.',
    },
    {
      id: '80ad09a3-6588-4842-bf30-fd702763a55b',
      question: 'Pre-death distributions from a modified endowment contract (MEC) receive different tax treatment than other life insurance policies because',
      choices: [
        'the MEC has tax deductible premiums',
        'the MEC tends to be an investment vehicle',
        'the MEC is considered an illegal product',
        'the MEC does not accumulate cash value'
      ],
      type: 'MCQs',
      correctAnswers: ['the MEC tends to be an investment vehicle'],
      score: 1,
      additionalInfo: 'A modified endowment contract (MEC) receives different tax treatment on pre-death distributions than other life insurance policies because it tends to be an investment vehicle.',
    },
    {
      id: 'cdeee3d5-ad7f-4e77-9190-437cbac20dea',
      question: 'Pat owns a 20-pay life policy with a paid-up dividend option. Which of the following statements is true?',
      choices: [
        'The policy\'s premiums will increase after 20 years',
        'The policy\'s cash values steadily decrease after 20 years',
        'The policy may be paid up early by using accumulated cash values',
        'The policy may be paid up early by using policy dividends'
      ],
      type: 'MCQs',
      correctAnswers: ['The policy may be paid up early by using policy dividends'],
      score: 1,
      additionalInfo: 'In this situation, the insured may pay up the\n' +
          'policy early by using policy dividends.',
    },
    {
      id: '17fa9cbe-8c84-4ac8-89f4-4b6d2be563d0',
      question: 'If an insured dies during the grace period with no premiums paid',
      choices: [
        'all past premiums will be refunded with interest',
        'the claim would be denied',
        'the policy would be payable, minus the premium amount',
        'the policy would be payable only after the beneficiary makes past due premium payment'
      ],
      type: 'MCQs',
      correctAnswers: ['the policy would be payable, minus the premium amount'],
      score: 1,
      additionalInfo: 'If the insured dies during the grace period and premiums have NOT been paid, the policy would be payable, less the premium amount.',
    },
    {
      id: 'b495c5f5-1de7-4395-a46f-5e3308d0c809',
      question: 'If an insured dies during the grace period with no premiums paid',
      choices: [
        'all past premiums will be refunded with interest',
        'the claim would be denied',
        'the policy would be payable, minus the premium amount',
        'the policy would be payable only after the beneficiary makes past due premium payment'
      ],
      type: 'MCQs',
      correctAnswers: ['the policy would be payable, minus the premium amount'],
      score: 1,
      additionalInfo: 'If the insured dies during the grace period and premiums have NOT been paid, the policy would be payable, less the premium amount.',
    },
    {
      id: '6043378e-c71a-46e7-a45b-bb93ab3b5805',
      question: 'A life insurance illustration showing future premiums being paid out of nonguaranteed values must disclose that',
      choices: [
        'These policy premiums will vanish',
        'The policy guarantees payment of these premiums',
        'The policyowner forfeits the option of paying these premiums from other',
        'The policyowner may need to resume premium payments depending on actual results'
      ],
      type: 'MCQs',
      correctAnswers: ['The policyowner may need to resume premium payments depending on actual results'],
      score: 1,
      additionalInfo: 'A life insurance illustration showing future premiums being paid out of nonguaranteed values must disclose that the policyowner may need to resume premium payments, depending on actual results.',
    },
    {
      id: 'a2dd91d1-5307-43ea-9be0-918dda60b432',
      question: 'Which of the following does a life insurance policy summary normally include?',
      choices: [
        'Stated beneficiary',
        'Agent\'s report',
        'Policyowner\'s MIB report',
        'The policy\'s cash value'
      ],
      type: 'MCQs',
      correctAnswers: ['The policy\'s cash value'],
      score: 1,
      additionalInfo: 'A life insurance policy summary typically includes the cash values of the policy.',
    },
    {
      id: '26f56a1a-8f6f-4be1-8856-a08b2561da54',
      question: 'What happens when an insurance policy is backdated?',
      choices: [
        'The time frame for reinstating a lapsed policy is extended',
        'The policy\'s probation period is earlier than the present',
        'The policy\'s effective date is earlier than the present',
        'The policy\'s elimination period is waived'
      ],
      type: 'MCQs',
      correctAnswers: ['The policy\'s effective date is earlier than the present'],
      score: 1,
      additionalInfo: 'Backdating on an insurance policy is the practice of making the policy effective on an earlier date than the present.',
    },
    {
      id: '0e92b23f-ef46-4b0a-a4e0-0324ce912d67',
      question: 'Dorian exercised a nonforfeiture option by using his life policy\'s cash value to purchase an extended term insurance option. When the term insurance expires,',
      choices: [
        'he has the option of resuming the original policy and paying the same premium',
        'all remaining cash values are paid to the policyowner',
        'the coverage can be extended with a lump sum payment',
        'the protection ends'
      ],
      type: 'MCQs',
      correctAnswers: ['the protection ends'],
      score: 1,
      additionalInfo: 'When the term insurance expires in an extended term option, there is no more protection.',
    },
    {
      id: 'af984c17-3c2c-4b8a-893a-47b79e70fbd3',
      question: 'The free-look provision gives the policyowner',
      choices: [
        'the right to return the policy for a full refund within a specified number of days',
        'the right to contest the terms of the policy',
        'the right to change a policy provision',
        'the right to return the policy for a partial refund within a specified number of days'
      ],
      type: 'MCQs',
      correctAnswers: ['the right to return the policy for a full refund within a specified number of days'],
      score: 1,
      additionalInfo: 'Within a specified number of davs, a free-look provision gives the policyholder the right to return the policy for a full refund.',
    },
    {
      id: '1aa2171e-47e5-4571-ac7e-d7d1ab4f403a',
      question: 'Which federal government agency enforces the security laws enacted by Congress?',
      choices: [
        'The National Securities Regulatory Commission',
        'The Securities Investment Commission',
        'The Variable Investment Commission',
        'The Securities Exchange Commission'
      ],
      type: 'MCQs',
      correctAnswers: ['The Securities Exchange Commission'],
      score: 1,
      additionalInfo: 'The Securities Exchange Commission is the federal government agency which enforces the security laws enacted by Congress.',
    },
    {
      id: 'd4f31635-9de7-47b4-b718-3949cc8de83f',
      question: 'Who were Keogh plans designed to provide pension benefits for?',
      choices: [
        'Public school employees',
        'Government employees',
        'The self-employed',
        'Corporate officers'
      ],
      type: 'MCQs',
      correctAnswers: ['The self-employed'],
      score: 1,
      additionalInfo: 'Keogh plans were designed to provide pension benefits for self-employed individuals.',
    },
    {
      id: '541070f4-51d0-4174-ac1e-dcd2468834e6',
      question: 'The elimination period under a hospital indemnity plan is',
      choices: [
        'the period in which pre-existing conditions are not taken into consideration',
        'the specified number of days an insured must wait before becoming eligible to receive benefits for each hospitalization',
        'the specified number of days after an insurance policy\'s issue date during which coverage is not afforded for sickness',
        'the period in which all deductibles are eliminated'
      ],
      type: 'MCQs',
      correctAnswers: ['the specified number of days an insured must wait before becoming eligible to receive benefits for each hospitalization'],
      score: 1,
      additionalInfo: 'In a hospital indemnity plan, an elimination period refers to the number of days an insured must wait before becoming eligible to receive benefits for each hospital stay.',
    },
    {
      id: 'f78a123a-3ac5-43d2-9342-5931247092b9',
      question: 'A disability elimination period is best described as a',
      choices: [
        'time deductible',
        'dollar deductible',
        'probation period',
        'eligibility period'
      ],
      type: 'MCQs',
      correctAnswers: ['time deductible'],
      score: 1,
      additionalInfo: 'The best wav to describe a disability elimination period is a "time deductible".',
    },
    {
      id: 'fbc17380-e91f-4875-9250-3716d3d41b41',
      question: 'What is the elimination period of an individual disability policy?',
      choices: [
        'Time period after the policy issue date in which the provisions are still contestable',
        'Time period an insured must wait before coverage begins',
        'The point in time when benefits are no longer payable',
        'Time period a disabled person must wait before benefits are paid'
      ],
      type: 'MCQs',
      correctAnswers: ['Time period a disabled person must wait before benefits are paid'],
      score: 1,
      additionalInfo: 'The elimination period of an individual disability insurance policy refers to the amount of time a disabled person must wait before benefits are paid.',
    },
    {
      id: 'f1d1218a-c3f6-448f-810d-c70e8823b1be',
      question: 'An insurer may pay a commission in which of the following scenarios?',
      choices: [
        'To an unlicensed person who has sold, solicited, or negotiated a policy of insurance on behalf of the insurer',
        'To a licensed non-appointed producer who has sold, solicited, or negotiated a policy of insurance on behalf of the insurer',
        'To a licensed producer who is appointed with the insurer',
        'When a policy is cancelled back to inception'
      ],
      type: 'MCQs',
      correctAnswers: ['To a licensed producer who is appointed with the insurer'],
      score: 1,
      additionalInfo: 'An insurer may pay a commission to a licensed producer who is appointed with the insurer.',
    },
    {
      id: 'f4037283-d095-443b-bfbe-5aa0133bcd5b',
      question: 'A field underwriter\'s main task is',
      choices: [
        'assign a risk classification to the insured',
        'to approve or decline an applicant',
        'report medical information to the Medical Information Bureau (MIB)',
        'to ensure an applicant\'s medical information is accurate and complete'
      ],
      type: 'MCQs',
      correctAnswers: ['to ensure an applicant\'s medical information is accurate and complete'],
      score: 1,
      additionalInfo: 'One of the main tasks of a field underwriter is to ensure the accuracy and completeness of an individual\'s medical information.',
    },
    {
      id: '7a42b06c-d7bf-4b5d-be74-33d29e774da8',
      question: 'A field underwriter\'s main task is',
      choices: [
        'assign a risk classification to the insured',
        'to approve or decline an applicant',
        'report medical information to the Medical Information Bureau (MIB)',
        'to ensure an applicant\'s medical information is accurate and complete'
      ],
      type: 'MCQs',
      correctAnswers: ['to ensure an applicant\'s medical information is accurate and complete'],
      score: 1,
      additionalInfo: 'One of the main tasks of a field underwriter is to ensure the accuracy and completeness of an individual\'s medical information.',
    },
    {
      id: 'ba12a8eb-d6e5-459f-af52-6bcf65f399ca',
      question: 'Replacing an insurance policy from one insurer to another based on misrepresentation is called',
      choices: [
        'unfair discrimination',
        'misrepresentation',
        'twisting',
        'rebating'
      ],
      type: 'MCQs',
      correctAnswers: ['twisting'],
      score: 1,
      additionalInfo: '"twisting". Replacing an insurance policy from one insurer to another based on misrepresentation is called twisting.',
    },
    {
      id: '3b3f078d-f3d9-47d0-aa0c-5e03ac8c7276',
      question: 'Using misrepresentations or inaccurate comparisons to induce a person to terminate or borrow against their current insurance policy to take out an insurance policy with another insurer is called',
      choices: [
        'Sliding',
        'Rebating',
        'Twisting',
        'Coercion'
      ],
      type: 'MCQs',
      correctAnswers: ['Twisting'],
      score: 1,
      additionalInfo: 'Using misrepresentations or inaccurate comparisons to induce a person to terminate or borrow against their current insurance policy to take out an insurance policy with another insurer is called twisting.',
    },
    {
      id: 'cc8c947f-07c3-4c08-b770-3dc354024c09',
      question: 'The renewal period for a producer\'s license is a',
      choices: [
        'one-year term',
        'three-year term',
        'four-year term',
        'two-year term'
      ],
      type: 'MCQs',
      correctAnswers: ['two-year term'],
      score: 1,
      additionalInfo: 'The renewal period for a producer\'s license is a two-year term.',
    },
    {
      id: '7405dc08-66cf-453d-815a-7173729a2c4b',
      question: 'In an insurance contract, the insurer is the only party legally obligated to perform. Because of this, an insurance contract is considered',
      choices: [
        'unilateral',
        'conditional',
        'aleatory',
        'voidable'
      ],
      type: 'MCQs',
      correctAnswers: ['unilateral'],
      score: 1,
      additionalInfo: 'A contract in which only the insurer would be legally obligated to perform is considered unilateral.',
    },
    {
      id: 'c43664a8-5c34-423c-a66b-90d89b30a066',
      question: 'A life insurance policy that is subject to a contract interest rate is referred to as',
      choices: [
        'universal life',
        'term life',
        'adjustable life',
        'group life'
      ],
      type: 'MCQs',
      correctAnswers: ['universal life'],
      score: 1,
      additionalInfo: 'Universal life is subject to a contract interest rate or a current annual interest rate.',
    },
    {
      id: 'dc7c5b1e-0cdb-49e6-af41-020ed09df2d0',
      question: 'Joe has a life insurance policy that has a face amount of $300,000. After a number of years, the policy\'s cash value accumulates to $50,000 and the face amount becomes $350,000. What kind of policy is this?',
      choices: [
        'Universal Life policy',
        'Increasing Term Life policy',
        'Nonparticipating policy',
        'Modified Whole Life policy'
      ],
      type: 'MCQs',
      correctAnswers: ['Universal Life policy'],
      score: 1,
      additionalInfo: 'This is an example of a Universal Life policy.',
    },
    {
      id: '0d8ebf11-e24e-4356-8051-351d089cf1ec',
      question: 'A partial surrender is allowed in which of the following life policies?',
      choices: [
        'Limited whole life',
        'Decreasing term life',
        'Adjustable whole life',
        'Universal life'
      ],
      type: 'MCQs',
      correctAnswers: ['Universal life'],
      score: 1,
      additionalInfo: 'Universal life insurance policies will allow for a partial surrender.',
    },
    {
      id: '3311240a-2d55-44d2-b9fd-c2be1148977a',
      question: 'Craig submits a $500 claim for medical expenses. With a past due premium of $100, the insurer pays $400. Which of the Uniform Optional Provisions covers this situation?',
      choices: [
        'Unpaid premium',
        'Payment of claims',
        'Legal actions',
        'Time of payment of claims'
      ],
      type: 'MCQs',
      correctAnswers: ['Unpaid premium'],
      score: 1,
      additionalInfo: 'If there is an unpaid premium at the time a claim becomes payable, the amount of the premium is to be deducted from the sum payable to the insured or beneficiary.',
    },
    {
      id: '1c826208-1ef9-4e9e-8fa6-43e476c39b1b',
      question: 'Under a Traditional IRA, interest earned is taxed',
      choices: [
        'during the accumulation phase',
        'upon distribution',
        'only if withdrawn prior to age 59 1/2',
        'according to the capital gains rate'
      ],
      type: 'MCQs',
      correctAnswers: ['upon distribution'],
      score: 1,
      additionalInfo: 'Interest earned on a Traditional IRA is taxed upon distribution at ordinary income tax rates.',
    },
    {
      id: '8545c813-f41b-432b-a4ec-b41281690092',
      question: 'A securities license is required for a life insurance producer to sell',
      choices: [
        'variable life insurance',
        'Modified Endowment Contracts (MEC)',
        'modified life insurance',
        'universal life insurance'
      ],
      type: 'MCQs',
      correctAnswers: ['variable life insurance'],
      score: 1,
      additionalInfo: 'A life insurance producer needs to possess a securities license to sell variable annuities.',
    },
    {
      id: '7fe33345-ed9e-4bad-b186-9cfab9e48b2c',
      question: 'Which type of policy combines the flexibility of a universal life policy with investment choices?',
      choices: [
        'Variable universal life policy',
        'Adjustable universal life policy',
        'Modified universal life policy',
        'Flexible universal life policy'
      ],
      type: 'MCQs',
      correctAnswers: ['Variable universal life policy\''],
      score: 1,
      additionalInfo: 'When the structure and flexibility of a universal life policy are combined with investment choices, the result is a variable universal life policy.',
    },
    {
      id: '022cf3e4-11c3-466e-99e4-0c1ef4900521',
      question: 'Which type of policy combines the flexibility of a universal life policy with investment choices?',
      choices: [
        'Variable universal life policy',
        'Adjustable universal life policy',
        'Modified universal life policy',
        'Flexible universal life policy'
      ],
      type: 'MCQs',
      correctAnswers: ['Variable universal life policy'],
      score: 1,
      additionalInfo: 'When the structure and flexibility of a universal life policy are combined with investment choices, the result is a variable universal life policy.',
    },
    {
      id: '1a7081dc-9f79-4ca2-9d6c-c08ca33966e8',
      question: 'A life insurance policy which contains cash values that vary according to its investment performance of stocks is called',
      choices: [
        'Adjustable Whole Life',
        'Increasing Term Life',
        'Variable Whole Life',
        'Modified Whole Life'
      ],
      type: 'MCQs',
      correctAnswers: ['Variable Whole Life'],
      score: 1,
      additionalInfo: 'A Variable Whole Life policy has cash values that vary according to the investment performance of common stocks.',
    },
    {
      id: '7113887f-1e97-4c03-ba90-91f76c231d2b',
      question: 'What is the primary factor that determines the benefits paid under a disability income policy?',
      choices: [
        'Type of occupation',
        'Age',
        'Education level',
        'Wages'
      ],
      type: 'MCQs',
      correctAnswers: ['Wages'],
      score: 1,
      additionalInfo: 'The major factor in determining the benefit amount paid under a disability income policy is wages.',
    },
    {
      id: '4693f4fa-b74e-49a4-af54-f892d38ccf7d',
      question: 'All of the following riders can increase the death benefit amount EXCEPT',
      choices: [
        'Accidental Death Rider',
        'Guaranteed Insurability',
        'Waiver of Premium',
        'Cost of Living'
      ],
      type: 'MCQs',
      correctAnswers: ['Waiver of Premium'],
      score: 1,
      additionalInfo: 'All of the following riders can increase the death benefit amount EXCEPT the Waiver of Premium.',
    },
    {
      id: '50049c03-dacd-4bba-b3f1-8d81596aa541',
      question: 'What guarantees that the statements supplied by an insurance applicant are true?',
      choices: [
        'Promise',
        'Assurance',
        'Representation',
        'Warranty'
      ],
      type: 'MCQs',
      correctAnswers: ['Warranty'],
      score: 1,
      additionalInfo: '"Warranty". A warranty is considered a statement of fact or something guaranteed to be true. Breach of warranties can be considered grounds for voiding the policy.',
    },
    {
      id: '6b4bb467-9128-4970-8bee-4c026e3db970',
      question: 'When can a group health policy renewal be denied according to the Health Insurance Portability and Accountability Act (HIPAA)?',
      choices: [
        'When contribution or participation rules have been violated',
        'When group participation has increased by 25%',
        'When a change of management has occurred within the group',
        'When the annual number of claims has increased by 25%'
      ],
      type: 'MCQs',
      correctAnswers: ['When contribution or participation rules have been violated'],
      score: 1,
      additionalInfo: 'According to HIPAA, a group health policy renewal can be denied when participation or contribution rules have been violated.',
    },
    {
      id: 'fdc6ccd3-b4a4-4509-9308-30218383ab21',
      question: 'A 70-year old insured individual has suffered from kidney failure for the past 24 months. She is covered by her spouse\'s large-group employer plan. How will Medicare be utilized in this situation?',
      choices: [
        'Will be the secondary insurer and pay for claims not fully covered by the group plan',
        'Will be the primary insurer and the group plan will be secondary',
        'Will be the primary insurer and pay for 100% of covered expenses',
        'Will not pay for any of the covered expenses'
      ],
      type: 'MCQs',
      correctAnswers: ['Will be the secondary insurer and pay for claims not fully covered by the group plan'],
      score: 1,
      additionalInfo: 'If you are eligible for Medicare because of kidney failure and are also covered by a group health plan, your group plan will pay first on your hospital and medical bills for 30 months. During this time, Medicare pays second.',
    },
    {
      id: '55cdfb4a-627a-4b44-af21-2d38114b0595',
      question: 'Under Washington law, the Buyer\'s Guide and Policy Summary MUST be delivered',
      choices: [
        'with or prior to the policy delivery',
        'no later than 30 days after the policy delivery',
        'no later than 20 days after the policy delivery',
        'no later than 10 days after the policy delivery'
      ],
      type: 'MCQs',
      correctAnswers: ['with or prior to the policy delivery'],
      score: 1,
      additionalInfo: 'Under Washington law, the Buyer\'s Guide and Policy Summary must be delivered with or prior to the policy delivery.',
    },
  ],
}
