import { ReactNode } from 'react'
import { ReactComponent as Gatsby } from '../assets/icons/gatsby.svg'
import { ReactComponent as WaStateIcon } from '../assets/icons/wa-state.svg'

type QuizTopic = {
  title: string
  icon: ReactNode
  disabled?: boolean
}

export const quizTopics: QuizTopic[] = [
  {
    title: 'Washington',
    icon: <WaStateIcon />,
  },
  {
    title: 'Virginia',
    icon: <Gatsby />,
    disabled: true,
  },
]
